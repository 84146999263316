import BaseListPresenter from "../../../base/BaseListPresenter";
import { findObjectUseCase } from "../../../usecases/object";

class DashboardEndorserPresenter extends BaseListPresenter {
  init() {
    const user = this.view.getCurrentUser();
    this.limit = 20;
    this.where = {
      clientName: user.name,
    };
    this.search = {};
    this.filter = {};
    this.include = ["all"];
    this.keys = undefined; // if keys are specified, only those keys will be returned
    this.sort = { createdAt: -1 };
    this.progress = true;
    this.reset();
    this.userAgent = [];
    this.newEndorseLetter = [];
    this.filterDate = {};
    this.filterLocation = "";
    this.pendingEndorseLetter = [];
    this.cancelledEndorseLetter = [];
  }

  componentDidMount() {
    this.init();
    return this.getObjects();
  }

  createQuery() {
    const skip = (this.current - 1) * this.limit;
    const query = {
      limit: this.limit,
      skip: skip,
      where: {
        ...this.where,
        ...this.search,
        ...this.filter,
        ...this.filterDate,
      },
      include: this.include,
    };
    if (this.sort) {
      query.sort = this.sort;
    }
    const keys = this.keys || this.view.getKeys() || [];
    if (keys.length > 0) {
      query.keys = keys;
    }
    return query;
  }
  async findObjects() {
    const collection = this.view.getCollectionName();

    const skip = (this.current - 1) * this.limit;
    const query = this.createQuery();

    try {
      this.showProgress();
      this.findObjectUseCase.abort();
      const objects = await this.findObjectUseCase.execute(
        "endorsement",
        query
      );
      this.objects = this.objects.concat(objects);
      console.log("dash", this.objects);
      this.view.setTotal(this.objects.length);
      this.view.setObjects(this.objects);
      this.hideProgress();
    } catch (error) {
      this.hideProgress();
      this.view.showError(error);
    }
    this.progress = false;
  }

  onChangeDate(where) {
    this.filterDate = where;
    this.getObjects();
  }

  async calculateCancelledInfo() {
    const user = this.view.getCurrentUser();
    const endorsementIds = user.endorsement?.map((e) => e.id);

    console.log("ed", user);

    const query = {
      where: {
        status: "Cancelled",
        // id: { $in: endorsementIds },
        region: user.regions[0]?.name,
      },
    };
    const newEndorse = await findObjectUseCase().execute("endorsement", query);
    console.log("new", newEndorse);
    this.cancelledEndorseLetter = newEndorse;
  }

  calculatePendingInfo() {
    return {
      value: this.objects
        ?.filter((o) => o.status === "Pending")
        .map((o) => o.statusQA)?.length,
      percentage: 0,
    };
  }

  calculateDispatchInfo() {
    return {
      value: this.objects
        ?.filter((o) => o.status === "For Dispatch")
        .map((o) => o.statusQA)?.length,
      percentage: 0,
    };
  }
  calculateCIInfo() {
    return {
      value: this.objects
        ?.filter((o) => o.status === "For CI")
        .map((o) => o.statusQA)?.length,
      percentage: 0,
    };
  }

  calculateQAInfo() {
    return {
      value: this.objects
        ?.filter((o) => o.status === "On going")
        .map((o) => o.status)?.length,
      percentage: 0,
    };
  }

  calculateDoneInfo() {
    return {
      value: this.objects
        ?.filter((o) => o.status === "Done")
        .map((o) => o.statusQA)?.length,
      percentage: 0,
    };
  }

  calculateTotalEndorsementInfo() {
    return {
      value: this.objects?.length,
      percentage: 0,
    };
  }

  calculateCancelled() {
    // const { cancelled } = this.calculateCancelledInfo();
    // console.log("cancelled", this.objects);
    // return {
    //   value: this.cancelledEndorseLetter?.length,
    //   percentage: 0,
    // };
    return {
      value: this.objects
        ?.filter((o) => o.status === "Cancelled")
        .map((o) => o.status)?.length,
      percentage: 0,
    };
  }

  filterSubmit(where) {
    const locationId = where.locationP?.id;
    this.reset();
    this.filterLocation = locationId;
    this.getObjects();
  }
}

export default DashboardEndorserPresenter;
