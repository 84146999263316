import BaseFormPresenter from "../../base/BaseFormPresenter";
import {
  findObjectUseCase,
  upsertUseCase,
  saveObjectUseCase,
} from "../../usecases/object";
import { v4 as uuidv4 } from "uuid";

class QAFormCIPresenter extends BaseFormPresenter {
  onChangeObject(object) {
    this.change = object;
    this.object = object;
  }

  componentDidMount() {
    this.init();
    this.getObject();
    this.getAnswer();
  }

  async getObject() {
    const collection = this.view.getCollectionName();
    const id = this.object.id || this.view.getObjectId();
    if (id) {
      const params = {
        include: [
          "selectedClient",
          "categories",
          "categories.questions",
          "categories.questions.choices",
          "uploadRelation",
        ],
      };
      try {
        this.view.showProgress();
        this.object = await this.getObjectUseCase.execute(collection, id, {
          params,
        });
        this.view.hideProgress();
        const answer = { answers: [] };
        answer.form = { id: this.object.id };
        // this.view.setAnswer(answer);
        this.view.setObject(this.object);
      } catch (error) {
        this.view.hideProgress();
        this.view.showError(error);
      }
    }
  }

  getAnswer() {
    const collection = "bvr_forms_answer_mis";
    const params = this.view.getAnswerId();
    console.log("p", params);
    const answerId = this.view.getAnswerId();
    const query = {
      where: { id: answerId },
      // include: [
      //   "form.categories.questions",
      //   "answers",
      //   "answers.question",
      //   "uploads",
      //   "brand",
      // ],
      include: ["form", "answers", "answers.choices", "uploads"],
    };

    if (answerId) {
      this.view.showProgress();
      findObjectUseCase()
        .execute(collection, query)
        .then(([object]) => {
          console.log("get answer object: ", object);
          this.view.hideProgress();
          this.view.setObject(object);
          // this.view.setForm(object.form);
          // this.view.setBrand(object.brand?.id);
          const answer = object;
          console.log("why", answer);
          this.view.setAnswer(answer);
          // this.view.getFile(object);
        })
        .catch((error) => {
          this.view.hideProgress();
          this.view.showError(error);
        });
    }
  }

  async save() {
    const objectUpload = this.view.getUpload();
    const objectAnswer = this.view.getAnswer();
    objectAnswer.uploadRelation = objectUpload;
    const collectionEndorsement = "endorsement";
    const collectionUsers = "users";
    const currentDate = new Date().toISOString();
    const url = this.view.getParams();
    const agentUser = this.view.getCurrentUser();

    const parts = url["*"].split("/");
    console.log("parts", parts);
    const secondPart = parts[1];
    const indexPart = parts[2];
    const query = {
      where: {
        id: secondPart,
      },
    };
    const queryUser = {
      sort: {
        createdAt: 1,
      },
      include: ["All", "roles.name", "location.name"],
    };
    const users = await findObjectUseCase().execute("users", queryUser);

    // Filter users with the role QA
    const qaUsers = users.filter((user) =>
      user.roles.some((role) => role.id === "QA")
    );

    // Sort MISUsers by createdAt to establish initial order
    qaUsers.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

    const sortedQAUsers = qaUsers.sort((a, b) => {
      const aEndorsementCount = a.endorsement ? a.endorsement.length : 0;
      const bEndorsementCount = b.endorsement ? b.endorsement.length : 0;
      console.log("aE", aEndorsementCount);
      console.log("bE", bEndorsementCount);
      const check = aEndorsementCount === bEndorsementCount ? true : false;
      console.log("check", check);

      if (aEndorsementCount === bEndorsementCount) {
        return new Date(a.createdAt) - new Date(b.createdAt);
      }
      const minus = aEndorsementCount - bEndorsementCount;
      console.log("minus", minus);
      return minus;
    });

    // Select the user with the fewest endorsements, or the earliest created if counts are equal
    const userForQA = sortedQAUsers[0];
    console.log("Next user for QA:", userForQA);

    const findEndorsement = await findObjectUseCase().execute(
      collectionEndorsement,
      query
    );

    let endorsementQA = findEndorsement[0];

    const queryEndorsementQA = {
      where: {
        id: endorsementQA.id,
      },
    };

    const findEndorsementQa = await findObjectUseCase().execute(
      "endorsement",
      queryEndorsementQA
    );

    let options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
      timeZone: "Asia/Manila",
    };
    const formatter = new Intl.DateTimeFormat("en-GB", options);
    let partss = formatter.formatToParts(new Date());
    const timeString = `${partss.find((p) => p.type === "year").value}-${
      partss.find((p) => p.type === "month").value
    }-${partss.find((p) => p.type === "day").value} ${
      partss.find((p) => p.type === "hour").value
    }:${partss.find((p) => p.type === "minute").value}:${
      partss.find((p) => p.type === "second").value
    }`;

    const formatDate = (date) => {
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
      const year = String(date.getFullYear()); // Get the last two digits of the year
      return `${year}-${month}-${day}`;
    };

    const date = new Date();
    const formattedDate = formatDate(date);

    endorsementQA.status = "On going";
    endorsementQA.statusQA = "Pending";

    endorsementQA.dateTimeSubmitted = timeString;
    if (qaUsers.some((qa) => qa.id === findEndorsementQa[0].createdBy)) {
      console.log("hey");
    } else {
      endorsementQA.createdBy = userForQA.id;
    }

    endorsementQA.agentSignature = agentUser.signature;
    endorsementQA.timeline = endorsementQA?.timeline || [];
    // endorsementQA.timeline?.push({
    //   time: formattedDate + " " + endorsementQA.startTime,
    //   status: "For CI",
    // });
    endorsementQA.timeline?.push({
      time: timeString,
      status: "On going",
    });
    // userForQA.endorsement = userForQA.endorsement || [];
    // userForQA.endorsement.push({
    //   id: secondPart,
    //   createdAt: currentDate,
    // });

    if (qaUsers.some((qa) => qa.id === findEndorsementQa[0].createdBy)) {
      console.log("hey");
    } else {
      endorsementQA.createdBy = userForQA.id;
    }

    const { upload, ...newObject } = objectAnswer;
    newObject.answers.forEach((answer) => {
      if (answer.choices && answer.choices.length > 0) {
        answer.choices = answer.choices.map((choice) => ({
          ...choice,
          id: uuidv4(),
        }));
      }
    });

    try {
      // const allImagesNotEmpty = newObject.uploadRelation.every(
      //   (image) => image.image !== ""
      // );

      // if (!allImagesNotEmpty) {
      //   this.view.setState({ imageRequired: true });
      //   return false;
      // }
      console.log("ANSWR SEND", newObject);
      console.log("ENDORSERMENT", endorsementQA);
      console.log("user", userForQA);

      const response = await this.upsertUseCase.execute(
        "bvr_forms_answer_mis",
        newObject
      );
      endorsementQA.qaFormId = response.id;
      endorsementQA.assignedQA = userForQA?.name;
      await this.upsertUseCase.execute(collectionEndorsement, endorsementQA);
      if (qaUsers.some((qa) => qa.id === findEndorsementQa[0].createdBy)) {
        return;
      } else {
        // await this.upsertUseCase.execute(collectionUsers, userForQA);
        const { password, ...userForQAWithoutPassword } = userForQA; // Exclude password
        console.log("userForQAWithoutPassword", userForQAWithoutPassword);
        await this.upsertUseCase.execute(
          collectionUsers,
          userForQAWithoutPassword
        );
      }
      this.change = {};
      return true;
    } catch (error) {
      throw error; // rethrow the error to be caught by the caller
    }
  }

  async submit() {
    try {
      this.view.submitting();
      // const saveSuccessful = await this.save();
      // console.log("SAVE SUCCESSFUL", saveSuccessful);
      // if (!saveSuccessful) {
      //   return;
      // } else {
      //   await this.save();
      //   this.view.submissionSuccess();
      //   this.view.showSuccessSnackbar("Successfully saved!");
      //   this.view.navigateBack();
      // }
      await this.save();
      this.view.submissionSuccess();
      this.view.showSuccessSnackbar("Successfully saved!");
      this.view.navigateBack();
    } catch (error) {
      this.view.submissionError(error);
      this.view.showError(error);
    }
  }
}

export default QAFormCIPresenter;
