import React from "react";

const styles = {
  mainContainer: {
    width: "204mm",
    height: "290mm",
    fontFamily: "Arial, sans-serif",
    padding: 0,
    margin: 0,
    backgroundColor: "white",
    position: "absolute",
  },
  backgroundImg: {
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundImage: `url(${require("../../../components/image/BVRPageOne.jpg")})`,
    width: "100%",
    height: "100%",
  },
  pageLength: {
    height: "100%",
    width: "100%",
  },
  signatureAlignment: {
    display: "flex",
    alignItems: "baseline",
  },
};

const headerData = (objects, style, formatText) => {
  return (
    <div
      style={{
        position: "absolute",
        width: "22rem",
        height: "1.3rem",
        ...style,
      }}
    >
      {formatText ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <label style={{ marginTop: ".7rem", fontSize: "9px" }}>
            {objects}
          </label>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <label style={{ marginTop: ".7rem", fontSize: "12px" }}>
            {objects}
          </label>
        </div>
      )}
    </div>
  );
};

const titlePage = (title, style) => {
  return (
    <div style={{ top: "11rem", position: "absolute", ...style }}>
      <p
        style={{
          color: "white",
          fontWeight: "bold",
          paddingLeft: "2rem",
        }}
      >
        {title}
      </p>
    </div>
  );
};

const imageLayout = (images) => {
  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        gap: "1rem",
      }}
    >
      {images.map((imageObj, index) => (
        <div
          key={index}
          style={{
            display: "grid",
            width: "45%",
            justifyContent: "center",
            // border: "3px solid",
            marginBottom: "1rem",
          }}
        >
          <label style={{ textAlign: "center" }}>{imageObj.label}</label>
          <div>
            <img
              src={imageObj.image}
              style={{ width: "100%", height: "100%" }}
              alt={`images-${index}`}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

function Report1({ objects, report, user, questions, labelQuestion }) {
  console.log("Object", objects);

  const repObj = report[0];
  console.log("RepObj: ", repObj);

  const formattedText = repObj?.answers[2]?.question?.choices
    .map((item, index) => `${index + 1}. ${item.text}`)
    .join("\n");

  const formattedText2 = repObj?.answers[5]?.question?.choices
    .map((item, index) => `${index + 1}. ${item.text}`)
    .join("\n");

  const formattedRemarks = repObj?.remarks
    .replace("*REMARKS: VERIFIED", "<strong>*REMARKS: VERIFIED</strong>")
    .replace(/\*/g, "<br>*");

  console.log("DFSFSDF: ", questions);

  return (
    <>
      <div className="bg-white" style={styles.mainContainer}>
        <div
          style={{
            ...styles.backgroundImg,
          }}
        >
          {/* start code */}
          {/* right  */}
          {headerData(objects?.borrowerName, {
            top: "4.6rem",
            left: "7rem",
          })}
          {headerData(objects?.employerAddress, {
            top: "6.8rem",
            left: "7rem",
          })}
          {headerData(repObj?.natureBusiness, {
            top: "8.6rem",
            left: "7rem",
          })}
          {headerData(objects?.loanTitle, {
            top: "10rem",
            left: "7rem",
          })}

          {/* left  */}
          {headerData(objects?.timeline[2].time, {
            top: "4.6rem",
            left: "34rem",
            width: "12.5rem",
          })}
          {headerData(objects?.clientName, {
            top: "6.8rem",
            left: "34rem",
            width: "12.5rem",
          })}
          {headerData(repObj?.operational, {
            top: "8.6rem",
            left: "34rem",
            width: "12.5rem",
          })}
          {headerData(repObj?.requesting, {
            top: "10rem",
            left: "34rem",
            width: "12.5rem",
          })}

          {titlePage("INFORMANTS INTERVIEWED")}
          {/* change object  */}
          {headerData(repObj?.answers[0]?.question?.question, {
            top: "14rem",
            left: "3.5rem",
            width: "38rem",
            display: "flex",
          })}
          {headerData(repObj?.answers[0]?.choices[0]?.text, {
            top: "15.3rem",
            left: "3.5rem",
            width: "38rem",
            display: "flex",
          })}

          {headerData(repObj?.answers[1]?.question?.question, {
            top: "14rem",
            left: "20.5rem",
            width: "17rem",
            display: "flex",
          })}
          {headerData(repObj?.answers[1]?.choices[0]?.text, {
            top: "15.3rem",
            left: "20.5rem",
            width: "17rem",
            display: "flex",
          })}

          {titlePage(labelQuestion[0]?.header, {
            top: "17.5rem",
            color: "yellow",
          })}
          {headerData(repObj.answers[2]?.question?.question, {
            top: "19.2rem",
            left: "2rem",
            width: "38rem",
            fontWeight: "bold",
            display: "flex",
          })}

          {headerData(repObj.answers[2]?.answers, {
            top: "19.2rem",
            left: "41.3rem",
            width: "5.4rem",
            fontWeight: "bold",
          })}

          {headerData(
            formattedText,
            {
              top: "20.2rem",
              height: "4.3rem",
              left: "2rem",
              width: "38rem",
              display: "flex",
              whiteSpace: "pre-wrap",
            },
            true
          )}
          {/* num 2 */}
          {headerData(questions[0]?.questionYes, {
            top: "24.7rem",
            left: "2rem",
            width: "38rem",
            fontWeight: "bold",
            display: "flex",
          })}

          {headerData(repObj.answers[3]?.answers, {
            top: "24.7rem",
            left: "41.3rem",
            width: "5.4rem",
            fontWeight: "bold",
          })}
          {/* num 3 */}
          {headerData(questions[1]?.questionYes, {
            top: "26rem",
            left: "2rem",
            width: "38rem",
            fontWeight: "bold",
            display: "flex",
          })}
          {headerData(repObj?.answers[4]?.answers, {
            top: "26rem",
            left: "41.3rem",
            width: "5.4rem",
            fontWeight: "bold",
          })}

          {headerData(repObj?.answers[5]?.question?.question, {
            top: "27.1rem",
            left: "2rem",
            width: "38rem",
            fontWeight: "bold",
            display: "flex",
          })}

          {headerData(repObj?.answers[5]?.answers, {
            top: "27.1rem",
            left: "41.3rem",
            width: "5.4rem",
            fontWeight: "bold",
          })}

          {headerData(
            formattedText2,
            {
              top: "28.1rem",
              height: "4.3rem",
              left: "2rem",
              width: "38rem",
              display: "flex",
              whiteSpace: "pre-wrap",
            },
            true
          )}
          {/* num 5  */}
          {headerData(questions[2]?.questionYes, {
            top: "31.8rem",
            left: "2rem",
            width: "38rem",
            fontWeight: "bold",
            display: "flex",
          })}
          {headerData(repObj?.answers[6]?.answers, {
            top: "31.8rem",
            left: "41.3rem",
            width: "5.4rem",
            fontWeight: "bold",
          })}

          {headerData(
            repObj?.answers[7]?.question?.question,
            {
              top: "34.6rem",
              left: "3.5rem",
              width: "16.5rem",
              display: "flex",
            },
            true
          )}
          {headerData(
            repObj?.answers[8]?.question?.question,
            {
              top: "34.6rem",
              left: "20.2rem",
              width: "9rem",
              display: "flex",
            },
            true
          )}
          {headerData(
            repObj?.answers[9]?.question?.question,
            {
              top: "34.6rem",
              left: "29.5rem",
              width: "8.5rem",
              display: "flex",
            },
            true
          )}

          {headerData(repObj?.answers[7]?.choices[0]?.text, {
            top: "35.8rem",
            left: "3.5rem",
            width: "16.5rem",
            display: "flex",
          })}
          {headerData(repObj?.answers[8]?.choices[0]?.text, {
            top: "35.8rem",
            left: "20.2rem",
            width: "9rem",
            display: "flex",
          })}
          {headerData(repObj?.answers[9]?.choices[0]?.text, {
            top: "35.8rem",
            left: "29.5rem",
            width: "8.5rem",
            display: "flex",
          })}

          {headerData(repObj?.answers[7]?.choices[1]?.text, {
            top: "37.1rem",
            left: "3.5rem",
            width: "16.5rem",
            display: "flex",
          })}
          {headerData(repObj?.answers[8]?.choices[1]?.text, {
            top: "37.1rem",
            left: "20.2rem",
            width: "9rem",
            display: "flex",
          })}
          {headerData(repObj?.answers[9]?.choices[1]?.text, {
            top: "37.1rem",
            left: "29.5rem",
            width: "8.5rem",
            display: "flex",
          })}
          {/* num 7 */}
          {headerData(questions[3]?.questionYes, {
            top: "39.3rem",
            left: "2rem",
            width: "38rem",
            fontWeight: "bold",
            display: "flex",
          })}
          {headerData(repObj?.answers[10]?.answers, {
            top: "39.3rem",
            left: "41.3rem",
            width: "5.4rem",
            fontWeight: "bold",
          })}

          {headerData(repObj?.answers[11]?.question?.question, {
            top: "42rem",
            left: "3.5rem",
            width: "16.5rem",
            display: "flex",
          })}
          {headerData(repObj?.answers[12]?.question?.question, {
            top: "42rem",
            left: "20.3rem",
            width: "16.5rem",
            display: "flex",
          })}

          {headerData(repObj?.answers[11]?.choices[0]?.text, {
            top: "43.2rem",
            left: "3.5rem",
            width: "16.5rem",
          })}
          {headerData(repObj?.answers[12]?.choices[0]?.text, {
            top: "43.2rem",
            left: "20.3rem",
            width: "16.5rem",
          })}

          {/* num 8 */}
          {headerData(questions[4]?.questionYes, {
            top: "45.5rem",
            left: "2rem",
            width: "38rem",
            fontWeight: "bold",
            display: "flex",
          })}
          {headerData(repObj?.answers[13]?.answers, {
            top: "45.5rem",
            left: "41.3rem",
            width: "5.4rem",
            fontWeight: "bold",
          })}

          {headerData(repObj?.answers[14]?.question?.question, {
            top: "48.2rem",
            left: "3.5rem",
            width: "16.5rem",
            display: "flex",
          })}
          {headerData(repObj?.answers[15]?.question?.question, {
            top: "48.2rem",
            left: "20.3rem",
            width: "16.5rem",
            display: "flex",
          })}

          {headerData(repObj?.answers[14]?.choices[0]?.text, {
            top: "49.4rem",
            left: "3.5rem",
            width: "16.5rem",
          })}
          {headerData(repObj?.answers[15]?.choices[0]?.text, {
            top: "49.4rem",
            left: "20.3rem",
            width: "16.5rem",
          })}

          {/* num 9 */}
          {headerData(questions[5]?.questionYes, {
            top: "53rem",
            left: "2rem",
            width: "38rem",
            fontWeight: "bold",
            display: "flex",
          })}

          {headerData(repObj?.answers[16]?.answers, {
            top: "53rem",
            left: "41.3rem",
            width: "5.4rem",
            fontWeight: "bold",
          })}

          {headerData(repObj?.answers[17]?.question?.question, {
            top: "55.5rem",
            left: "3.5rem",
            width: "16.5rem",
            display: "flex",
          })}
          {headerData(repObj?.answers[17]?.choices[0]?.text, {
            top: "56.7rem",
            left: "3.5rem",
            width: "16.5rem",
          })}

          {headerData(labelQuestion[1]?.questionYes, {
            top: "60.2rem",
            left: "2rem",
            width: "16.5rem",
            fontWeight: "bold",
            display: "flex",
          })}
          {headerData(repObj?.answers[18]?.choices[0]?.text, {
            top: "60.2rem",
            left: "20rem",
            width: "5rem",
            display: "flex",
          })}

          {headerData(repObj?.answers[19]?.question?.question, {
            top: "60.2rem",
            left: "30rem",
            width: "16.5rem",
            fontWeight: "bold",
            display: "flex",
          })}

          {headerData(repObj?.answers[19]?.choices[0]?.text, {
            top: "60.2rem",
            left: "36rem",
            width: "5rem",
            display: "flex",
          })}

          {/* num 10 */}
          {headerData(questions[6]?.questionYes, {
            top: "61.4rem",
            left: "2rem",
            width: "38rem",
            fontWeight: "bold",
            display: "flex",
          })}
          {headerData(repObj?.answers[19]?.answers, {
            top: "61.4rem",
            left: "41.3rem",
            width: "5.4rem",
            fontWeight: "bold",
          })}
          {headerData(repObj?.answers[21]?.question?.question, {
            top: "64rem",
            left: "3.5rem",
            width: "16.5rem",
            display: "flex",
          })}

          {headerData(repObj?.answers[21]?.choices[0]?.text, {
            top: "65rem",
            left: "3.2rem",
            width: "16.5rem",
          })}

          {headerData(repObj?.answers[21]?.answers, {
            top: "61.4rem",
            left: "41.3rem",
            width: "5.4rem",
            fontWeight: "bold",
          })}
        </div>
      </div>
      {/* page 2  */}
      <div
        className="bg-white"
        style={{
          ...styles.mainContainer,
          marginTop: "70.5rem",
        }}
      >
        <div
          style={{
            ...styles.backgroundImg,
            backgroundImage: `url(${require("../../../components/image/BVRPage2.jpg")})`,
            width: "97%",
            marginLeft: ".8rem",
          }}
        >
          {/* start code */}
          {/* num 12  */}
          {headerData(labelQuestion[2]?.questionYes, {
            top: "2.7rem",
            left: "2rem",
            width: "38rem",
            fontWeight: "bold",
            display: "flex",
          })}
          {headerData(repObj.answers[22]?.question?.question, {
            top: "5.2rem",
            left: "3.3rem",
            width: "9.5rem",
            display: "flex",
          })}

          {headerData(repObj.answers[22]?.choices[0]?.text, {
            top: "6.4rem",
            left: "3.3rem",
            width: "9.5rem",
            display: "flex",
          })}
          {headerData(repObj.answers[22]?.choices[1]?.text, {
            top: "7.6rem",
            left: "3.3rem",
            width: "9.5rem",
            display: "flex",
          })}
          {headerData(repObj.answers[22]?.choices[2]?.text, {
            top: "8.8rem",
            left: "3.3rem",
            width: "9.5rem",
            display: "flex",
          })}

          {headerData(repObj.answers[23]?.question.question, {
            top: "5.2rem",
            left: "13.3rem",
            width: "9.5rem",
            display: "flex",
          })}

          {headerData(repObj.answers[23]?.choices[0]?.text, {
            top: "6.4rem",
            left: "13.3rem",
            width: "9.5rem",
            display: "flex",
          })}
          {headerData(repObj.answers[23]?.choices[1]?.text, {
            top: "7.6rem",
            left: "13.3rem",
            width: "9.5rem",
            display: "flex",
          })}
          {headerData(repObj.answers[23]?.choices[2]?.text, {
            top: "8.8rem",
            left: "13.3rem",
            width: "9.5rem",
            display: "flex",
          })}

          {headerData(repObj.answers[24]?.question.question, {
            top: "5.2rem",
            left: "20.3rem",
            width: "17.5rem",
          })}

          {headerData(repObj.answers[24]?.choices[0]?.text, {
            top: "6.4rem",
            left: "20.3rem",
            width: "17.5rem",
          })}
          {headerData(repObj.answers[24]?.choices[1]?.text, {
            top: "7.6rem",
            left: "20.3rem",
            width: "17.5rem",
          })}
          {headerData(repObj.answers[24]?.choices[2]?.text, {
            top: "8.8rem",
            left: "20.3rem",
            width: "17.5rem",
          })}

          {/* 13 */}
          {headerData(labelQuestion[3]?.questionYes, {
            top: "11.3rem",
            left: "2rem",
            width: "38rem",
            fontWeight: "bold",
            display: "flex",
          })}
          {headerData(repObj.answers[25]?.question.question, {
            top: "13.8rem",
            left: "3.3rem",
            width: "9.8rem",
            display: "flex",
          })}

          {headerData(repObj.answers[25]?.choices[0]?.text, {
            top: "17.5rem",
            left: "3.3rem",
            width: "9.8rem",
            display: "flex",
          })}
          {headerData(repObj.answers[25]?.choices[1]?.text, {
            top: "20.5rem",
            left: "3.3rem",
            width: "9.8rem",
            display: "flex",
          })}
          {headerData(repObj.answers[25]?.choices[2]?.text, {
            top: "22rem",
            left: "3.3rem",
            width: "9.8rem",
            display: "flex",
          })}

          {headerData(repObj.answers[26]?.question.question, {
            top: "13.8rem",
            left: "13.3rem",
            width: "9.8rem",
            display: "flex",
          })}
          {headerData(repObj.answers[26]?.choices[0]?.text, {
            top: "17.5rem",
            left: "13.3rem",
            width: "9.8rem",
            display: "flex",
          })}
          {headerData(repObj.answers[26]?.choices[1]?.text, {
            top: "20.5rem",
            left: "13.3rem",
            width: "9.8rem",
            display: "flex",
          })}
          {headerData(repObj.answers[26]?.choices[2]?.text, {
            top: "22rem",
            left: "13.3rem",
            width: "9.8rem",
            display: "flex",
          })}

          {headerData(repObj.answers[27]?.question.question, {
            top: "13.8rem",
            left: "20.4rem",
            width: "9.8rem",
            display: "flex",
          })}
          {headerData(repObj.answers[27]?.choices[0]?.text, {
            top: "17.5rem",
            left: "20.4rem",
            width: "9.8rem",
            display: "flex",
          })}
          {headerData(repObj.answers[27]?.choices[1]?.text, {
            top: "20.5rem",
            left: "20.4rem",
            width: "9.8rem",
            display: "flex",
          })}
          {headerData(repObj.answers[27]?.choices[2]?.text, {
            top: "22rem",
            left: "20.4rem",
            width: "9.8rem",
            display: "flex",
          })}

          {/* {headerData(repObj.answers[25]?.choices[2]?.text, {
            top: "22rem",
            left: "13.3rem",
            width: "9.8rem",
            display: "flex",
          })} */}

          {headerData(repObj.answers[28]?.question.question, {
            top: "13.8rem",
            left: "29.8rem",
            width: "9.8rem",
            display: "flex",
          })}
          {headerData(repObj.answers[28]?.choices[0]?.text, {
            top: "17.5rem",
            left: "29.8rem",
            width: "9.8rem",
            display: "flex",
          })}
          {headerData(repObj.answers[28]?.choices[1]?.text, {
            top: "20.5rem",
            left: "29.8rem",
            width: "9.8rem",
            display: "flex",
          })}
          {headerData(repObj.answers[28]?.choices[2]?.text, {
            top: "22rem",
            left: "29.8rem",
            width: "9.8rem",
            display: "flex",
          })}
          {/* 14 */}
          {headerData(labelQuestion[4]?.questionYes, {
            top: "25.5rem",
            left: "2rem",
            width: "10rem",
            fontWeight: "bold",
            display: "flex",
          })}
          {headerData(repObj.answers[29]?.choices[0]?.text, {
            top: "25.5rem",
            left: "13.4rem",
            width: "16.8rem",
            display: "flex",
          })}
          {/* remarks  */}
          {headerData(
            <div dangerouslySetInnerHTML={{ __html: formattedRemarks }} />,
            {
              top: "28.3rem",
              left: "3.4rem",
              width: "34rem",
              height: "35rem",
              display: "flex",
            }
          )}
        </div>
      </div>
      {/* page 3  */}
      <div
        className="bg-white"
        style={{ ...styles.mainContainer, marginTop: "140.5rem" }}
      >
        {imageLayout(repObj.uploadRelation)}
        {/* start code */}
      </div>
      {/* page 4 */}
      <div
        className="bg-white"
        style={{ ...styles.mainContainer, marginTop: "210.5rem" }}
      >
        {/* start code */}
        <div style={styles.pageLength}>
          <div style={styles.signatureAlignment}>
            <div
              style={{
                width: "50%",
                display: "flex",
                alignItems: "baseline",
                justifyContent: "center",
              }}
            >
              <label style={{ fontSize: "12px" }}>CREDIT INVESTIGATOR </label>
              <div style={{ borderBottom: "2px solid", width: "15rem" }}>
                <img
                  src={objects?.agentSignature}
                  height={100}
                  width={300}
                  alt="Signature"
                />
              </div>
            </div>
            <div
              style={{
                width: "50%",
                display: "flex",
                alignItems: "baseline",
                justifyContent: "center",
              }}
            >
              <label style={{ fontSize: "12px" }}>Date: </label>
              <div style={{ borderBottom: "2px solid", width: "15rem" }}>
                {objects?.dateTimeSubmitted}
              </div>
            </div>
          </div>
          <div style={styles.signatureAlignment}>
            <div
              style={{
                width: "50%",
                display: "flex",
                alignItems: "baseline",
                justifyContent: "center",
              }}
            >
              <label style={{ fontSize: "12px" }}>REVIEWED BY: </label>
              <div style={{ borderBottom: "2px solid", width: "15rem" }}>
                <img
                  src={objects?.agentSignature}
                  height={100}
                  width={300}
                  alt="Signature"
                />
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div style={{ width: "25rem" }}>
              <p>
                <b>
                  {/* Forbes Financial Consultancy Corporation Unit 3405, 34/F
                  Raffles Corporate Center, Ortigas Center, Pasig City Tel No.
                  636-3053 */}
                  Forbes Financial Consultancy Corporation Unit 1407 14/F,
                  Jollibee Plaza Bldg, Emerald Avenue Ortigas Center, Pasig City
                </b>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Report1;

// import React from "react";

// const styles = {
//   mainContainer: {
//     width: "210mm",
//     height: "297mm",
//     fontFamily: "Arial, sans-serif",
//     padding: 0,
//     margin: 0,
//     backgroundColor: "white",
//     // position: "absolute",
//   },
//   backgroundImg: {
//     backgroundSize: "contain",
//     backgroundPosition: "center",
//     backgroundRepeat: "no-repeat",
//     backgroundImage: `url(${require("../../../components/image/BVRPageOne.jpg")})`,
//     width: "210mm",
//     height: "297mm",
//   },
//   pageLength: {
//     height: "100%",
//     width: "100%",
//   },
//   signatureAlignment: {
//     display: "flex",
//     alignItems: "baseline",
//   },
// };

// function Report1() {
//   return (
//     <div>
//       <div style={styles.mainContainer}>
//         <div
//           style={{
//             ...styles.backgroundImg,
//           }}
//         >
//           Report 1
//         </div>
//       </div>
//       <div style={styles.mainContainer}>
//         <div
//           style={{
//             ...styles.backgroundImg,
//             backgroundImage: `url(${require("../../../components/image/BVRPage2.jpg")})`,
//           }}
//         >
//           Report 2
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Report1;
