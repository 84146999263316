import React from "react";
import { InputJson } from "nq-component";
import {
  findObjectUseCase,
  getObjectUseCase,
  upsertUseCase,
} from "../../usecases/object";
import withRouter from "../../withRouter";
import BaseFormPage from "../../base/BaseFormPage";
import NavBar from "../../components/navbar";
import EndorsementFormPresenter from "./EndorsementFormPresenter";
import "./styles.css";
import InputFactory from "../../components/InputFactory";
import classNames from "../../classNames";
import LocationSelector from "../../components/LocationSelector";

class EndorsementFormPage extends BaseFormPage {
  constructor(props) {
    super(props);
    this.state = {
      object: {},
      advanced: false,
      type: [],
      selectedForm: null,
      dropdownOpen: false,
      selectedRegions: "",
      selectedProvinces: "",
      selectedCity: "",
      selectedBarangay: "",
    };
    this.presenter = new EndorsementFormPresenter(
      this,
      getObjectUseCase(),
      upsertUseCase()
    );
    this.wrapperRef = React.createRef();
    this.inputRef = React.createRef();
  }

  componentDidMount() {
    this.presenter.componentDidMount();
    this.getUserAgent();
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  getCollectionName() {
    return "endorsement";
  }

  onClickAdvance() {
    this.setState({ advanced: !this.state.advanced });
  }

  onChangeObject(object) {
    this.presenter.onChangeObject(object);
  }

  async getUserAgent() {
    const user = this.getCurrentUser();

    const query = {
      where: {
        "selectedClient.value": user.group.id,
      },
      include: ["all"],
    };

    const [bvr, evr, rvr] = await Promise.all([
      findObjectUseCase().execute("bvr_forms_mis", query),
      findObjectUseCase().execute("evr_forms_mis", query),
      findObjectUseCase().execute("rvr_forms_mis", query),
    ]);

    const formOptions = [
      ...bvr?.map((agent) => ({
        value: agent.id,
        label: agent.type,
      })),
      ...evr?.map((agent) => ({
        value: agent.id,
        label: agent.type,
      })),
      ...rvr?.map((agent) => ({
        value: agent.id,
        label: agent.type,
      })),
    ];

    this.setState({ type: formOptions });
  }

  handleUserChange = (selectedOption) => {
    this.setState({ selectedForm: selectedOption });
    const objectCopy = { ...this.state.object, rType: selectedOption.label };
    this.onChangeObject(objectCopy);
  };

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.setState({ dropdownOpen: false });
    }
  };

  openDropdown = () => {
    if (!this.state.dropdownOpen) {
      this.setState({ dropdownOpen: true });
    }
  };

  toggleDropdown = () => {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  };

  handleSelect = (selectedOption) => {
    this.setState({
      selectedForm: selectedOption,
      object: { ...this.state.object, rType: selectedOption.label },
      dropdownOpen: false, // Close the dropdown on selection
    });
    const objectCopy = { ...this.state.object, rType: selectedOption.label };
    this.onChangeObject(objectCopy);
  };

  handleLocationChange = (regions, provinces, city, barangay) => {
    this.setState({
      selectedRegions: regions,
      selectedProvinces: provinces,
      selectedCity: city,
      selectedBarangay: barangay,
    });
  };

  onSubmitForm(e) {
    e.preventDefault();
    this.presenter.submit();
  }

  render() {
    const { object, type, advanced, dropdownOpen } = this.state;
    const schema = this.getSchema(this.getCollectionName());
    if (!schema) return <h1>no schema</h1>;
    const label = this.getObjectId() === undefined ? "Add New " : "Edit ";
    return (
      <>
        <NavBar
          action={() => (
            <div className="dropdown dropstart d-inline-block">
              <i
                role="button"
                data-bs-toggle="dropdown"
                className="bi bi-three-dots-vertical"
              ></i>
              <div className="dropdown-menu fs-xs">
                <button
                  onClick={this.onClickAdvance.bind(this)}
                  className="dropdown-item py-3"
                >
                  <i className="bi bi-gear pe-2" />
                  Toggle Advance
                </button>
              </div>
            </div>
          )}
        />
        <div className="container-fluid overflow-auto">
          <div className="row h-100">
            <div className="col-12 p-3 p-lg-4">
              <h1 className="fw-bold mt-3 text-capitalize">
                {label + (schema.label || this.getCollectionName())}
              </h1>
              <div className="mt-3 bg-white shadow rounded p-3 px-lg-5 py-lg-4">
                <form onSubmit={this.onSubmitForm.bind(this)}>
                  <div className="row g-3">
                    {advanced ? (
                      <InputJson
                        defaultValue={object}
                        onChange={this.onChangeObject.bind(this)}
                        rows="15"
                      />
                    ) : (
                      <>
                        <p className="fw-bold mb-0">General Details</p>
                        <hr />
                        <div className="row">
                          <div
                            className="mb-3 col-12 col-md-6"
                            ref={this.wrapperRef}
                          >
                            <label className="mb-2" htmlFor="user">
                              Report Type
                            </label>
                            <div className="custom-select-wrapper">
                              <input
                                readOnly
                                className={classNames("form-control")}
                                value={object.rType}
                                placeholder="Select Report"
                                onClick={this.openDropdown} // Open dropdown when input is clicked
                                ref={this.inputRef}
                              />
                              {dropdownOpen && (
                                <ul className="custom-select-options">
                                  {type.map((option) => (
                                    <li
                                      key={option.value}
                                      onClick={() => this.handleSelect(option)}
                                      className="custom-select-option"
                                    >
                                      {option.label}
                                    </li>
                                  ))}
                                </ul>
                              )}
                            </div>
                          </div>
                          {/* <div className="mb-3 col-12 col-md-6">
                            <label className="form-label">Endorser Name</label>
                            <InputFactory
                              type="String"
                              field="endorserName"
                              object={object}
                              placeholder="Enter Endorser Name"
                              onChange={this.onChange.bind(this)}
                              required={true}
                            />
                          </div> */}

                          <div className="mb-3 col-12 col-md-6">
                            <label className="form-label">Loan Title</label>
                            <InputFactory
                              type="String"
                              field="loanTitle"
                              object={object}
                              placeholder="Enter Loan Title"
                              onChange={this.onChange.bind(this)}
                              required={true}
                            />
                          </div>
                        </div>
                        <p className="fw-bold mb-0">
                          {this.state.selectedForm?.label ===
                            "BUSINESS VERIFICATION (BVR)" ||
                          this.state.object.rType ===
                            "BUSINESS VERIFICATION (BVR)"
                            ? "Business Details"
                            : this.state.object.rType ===
                              "EMPLOYMENT VERIFICATION (EVR)"
                            ? "Employment Details"
                            : "Subject's Details"}
                        </p>
                        <hr />
                        <div className="row">
                          <div className="mb-3 col-12 col-md-4">
                            <label className="form-label">
                              {this.state.selectedForm?.label ===
                                "BUSINESS VERIFICATION (BVR)" ||
                              this.state.object.rType ===
                                "BUSINESS VERIFICATION (BVR)"
                                ? "Business Name"
                                : "Subject Name"}
                            </label>
                            <InputFactory
                              type="String"
                              field="residentName"
                              object={object}
                              placeholder={
                                this.state.selectedForm?.label ===
                                  "BUSINESS VERIFICATION (BVR)" ||
                                this.state.object.rType ===
                                  "BUSINESS VERIFICATION (BVR)"
                                  ? "Enter Business Name"
                                  : "Subject Name"
                              }
                              onChange={this.onChange.bind(this)}
                              required={true}
                            />
                          </div>

                          {this.state.selectedForm?.label ===
                            "EMPLOYMENT VERIFICATION (EVR)" ||
                          this.state.object.rType ===
                            "EMPLOYMENT VERIFICATION (EVR)" ? (
                            <div className="mb-3 col-12 col-md-4">
                              <label className="form-label">
                                Employer's Name
                              </label>
                              <InputFactory
                                type="String"
                                field="employerName"
                                object={object}
                                placeholder={
                                  this.state.selectedForm?.label ===
                                    "BUSINESS VERIFICATION (BVR)" ||
                                  this.state.object.rType ===
                                    "BUSINESS VERIFICATION (BVR)"
                                    ? "Enter Subject Name"
                                    : "Enter Employer's Name"
                                }
                                onChange={this.onChange.bind(this)}
                                required={true}
                              />
                            </div>
                          ) : null}

                          <div className="mb-3 col-12 col-md-4">
                            <label className="form-label">Contact Number</label>
                            <InputFactory
                              type="String"
                              field="residentContact"
                              object={object}
                              placeholder="Enter Contact Number"
                              onChange={this.onChange.bind(this)}
                              required={true}
                            />
                          </div>

                          {this.state.selectedForm?.label ===
                            "EMPLOYMENT VERIFICATION (EVR)" ||
                          this.state.object.rType ===
                            "EMPLOYMENT VERIFICATION (EVR)" ? (
                            <label>Employer's Address</label>
                          ) : null}
                          <LocationSelector
                            onLocationChange={this.handleLocationChange}
                            object={object.id ? object : null}
                          />
                          <div className="mb-3 col-12 col-md-4">
                            <label className="form-label">
                              Unit / Building
                            </label>
                            <InputFactory
                              type="String"
                              field="residentialAddress"
                              object={object}
                              placeholder="Enter Unit / Building"
                              onChange={this.onChange.bind(this)}
                              required={true}
                            />
                          </div>
                        </div>
                        {this.state.selectedForm?.label !==
                          "RESIDENCE VERIFICATION (RVR)" &&
                          this.state.selectedForm?.label !==
                            "EMPLOYMENT VERIFICATION (EVR)" &&
                          this.state.object.rType !==
                            "RESIDENCE VERIFICATION (RVR)" &&
                          this.state.object.rType !==
                            "EMPLOYMENT VERIFICATION (EVR)" && (
                            <p className="fw-bold mb-0">
                              {this.state.selectedForm?.label ===
                                "BUSINESS VERIFICATION (BVR)" ||
                              this.state.object.rType ===
                                "BUSINESS VERIFICATION (BVR)"
                                ? "Subject Details"
                                : "Employment Details"}
                            </p>
                          )}
                        <hr />
                        {this.state.selectedForm?.label !==
                          "RESIDENCE VERIFICATION (RVR)" &&
                          this.state.selectedForm?.label !==
                            "EMPLOYMENT VERIFICATION (EVR)" &&
                          this.state.object.rType !==
                            "RESIDENCE VERIFICATION (RVR)" &&
                          this.state.object.rType !==
                            "EMPLOYMENT VERIFICATION (EVR)" && (
                            <div className="row">
                              <div className="mb-3 col-12 col-md-4">
                                <label className="form-label">
                                  {this.state.selectedForm?.label ===
                                    "BUSINESS VERIFICATION (BVR)" ||
                                  this.state.object.rType ===
                                    "BUSINESS VERIFICATION (BVR)"
                                    ? "Subject Name"
                                    : "Employer's Name"}
                                </label>
                                <InputFactory
                                  type="String"
                                  field="employerName"
                                  object={object}
                                  placeholder={
                                    this.state.selectedForm?.label ===
                                      "BUSINESS VERIFICATION (BVR)" ||
                                    this.state.object.rType ===
                                      "BUSINESS VERIFICATION (BVR)"
                                      ? "Enter Subject Name"
                                      : "Enter Employer's Name"
                                  }
                                  onChange={this.onChange.bind(this)}
                                  required={true}
                                />
                              </div>
                              <div className="mb-3 col-12 col-md-4">
                                <label className="form-label">
                                  {this.state.selectedForm?.label ===
                                    "BUSINESS VERIFICATION (BVR)" ||
                                  this.state.object.rType ===
                                    "BUSINESS VERIFICATION (BVR)"
                                    ? "Subject Address"
                                    : "Employer's Address"}
                                </label>
                                <InputFactory
                                  type="String"
                                  field="employerAddress"
                                  object={object}
                                  placeholder={
                                    this.state.selectedForm?.label ===
                                      "BUSINESS VERIFICATION (BVR)" ||
                                    this.state.object.rType ===
                                      "BUSINESS VERIFICATION (BVR)"
                                      ? "Enter Subject Address"
                                      : "Enter Employer's Address"
                                  }
                                  onChange={this.onChange.bind(this)}
                                  required={true}
                                />
                              </div>
                              <div className="mb-3 col-12 col-md-4">
                                <label className="form-label">
                                  Contact Number
                                </label>
                                <InputFactory
                                  type="String"
                                  field="employerContact"
                                  object={object}
                                  placeholder="Enter Contact Number"
                                  onChange={this.onChange.bind(this)}
                                  required={true}
                                />
                              </div>
                            </div>
                          )}
                        <p className="fw-bold mb-0">Additional Information</p>
                        <hr />
                        <div className="row">
                          <div className="mb-3 col-12">
                            <label className="form-label">Notes</label>
                            <InputFactory
                              type="String"
                              _type="Text"
                              field="note"
                              object={object}
                              placeholder="Enter Notes"
                              onChange={this.onChange.bind(this)}
                              required={true}
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="mb-3 col-12">
                            <label className="form-label">Attachment</label>

                            <InputFactory
                              object={object}
                              onChange={this.onChange.bind(this)}
                              type="File"
                              field="file"
                              multiple
                              required={false}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="mt-4">
                    <button
                      type="submit"
                      className="btn text-dark fs-sm me-3"
                      style={{ backgroundColor: "#fdcb5b" }}
                    >
                      <i className="bi bi-file-earmark-check me-2"></i>SAVE
                    </button>
                    <button
                      type="button"
                      className="btn btn-light fs-sm"
                      onClick={this.onClickBack.bind(this)}
                    >
                      GO BACK
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(EndorsementFormPage);
