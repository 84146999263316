// import React from "react";
// import classNames from "../../classNames";

// function InputFile({ className, value, onChange, saveFile, ...props }) {
//   const [progress, setProgress] = React.useState(0);
//   function _onChange(e) {
//     const [file] = e.target.files;
//     console.log("ff", file);
//     const options = {
//       progress: (value) => setProgress(value * 100),
//     };
//     saveFile
//       .execute(file, options)
//       .then((result) => {
//         setProgress(0);
//         console.log("haha", result.url);
//         onChange(result.url);
//       })
//       .catch((error) => {
//         console.log("error", error);
//       });
//   }

//   return (
//     <input
//       className={classNames("form-control", className)}
//       type="file"
//       onChange={_onChange}
//       {...props}
//     />
//   );
// }

// export default InputFile;

import React from "react";
import classNames from "../../classNames";

function InputFileMultiple({
  className,
  value = [],
  onChange,
  saveFile,
  ...props
}) {
  const [progress, setProgress] = React.useState(0);
  const [fileURLs, setFileURLs] = React.useState(value); // Initialize with value if provided

  React.useEffect(() => {
    // Update fileURLs state whenever value changes
    if (value && value.length > 0) {
      setFileURLs(value);
    }
  }, [value]);

  function _onChange(e) {
    const files = e.target.files;
    const options = {
      progress: (value) => setProgress(value * 100),
    };
    const tempFileURLs = [];

    Array.from(files).forEach((file) => {
      saveFile
        .execute(file, options)
        .then((result) => {
          setProgress(0);
          tempFileURLs.push(result.url); // Store file URL
          if (tempFileURLs.length === files.length) {
            const allFileURLs = [...fileURLs, ...tempFileURLs]; // Combine previous file URLs and new ones
            setFileURLs(allFileURLs); // Update state with all file URLs
            onChange(allFileURLs); // Call onChange with all file URLs
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    });
  }

  function handleDeleteFile(index) {
    const updatedFileURLs = fileURLs.filter((_, i) => i !== index); // Remove the file by index
    setFileURLs(updatedFileURLs);
    onChange(updatedFileURLs); // Notify parent about the change
  }

  return (
    <div className="row align-items-center">
      {/* File icon */}
      {/* <div className="col-1 col-md-1 mb-3 mb-md-0 d-flex justify-content-center">
        <div
          className="border rounded p-2 d-flex justify-content-center align-items-center"
          style={{
            width: "60px",
            height: "70px",
            backgroundColor: "#fefefe",
          }}
        >
          <i
            className="bi bi-file-earmark-arrow-up text-muted"
            style={{ fontSize: "40px" }}
          ></i>
        </div>
      </div> */}

      {/* File input and label */}
      <div className="col-10 col-md-10">
        <label className="text-muted d-block">Please upload a file</label>
        <div>
          <input
            className={classNames("form-control", className)}
            type="file"
            onChange={_onChange}
            {...props}
            id="customFileUpload"
            multiple
            accept="image/*,.pdf,.doc,.docx,.xls,.xlsx,.txt"
          />
          <label
            htmlFor="customFileUpload"
            className="d-block mt-2"
            style={{ cursor: "pointer" }}
          >
            {fileURLs.length === 0
              ? "No file selected"
              : `${fileURLs.length} file(s) selected`}
          </label>
        </div>

        {/* Progress bar */}
        {progress > 0 && (
          <div className="progress mt-2">
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: `${progress}%`, backgroundColor: "#517EC1" }}
              aria-valuenow={progress}
              aria-valuemin="0"
              aria-valuemax="100"
            >
              {progress}%
            </div>
          </div>
        )}

        {/* Display uploaded files */}
        {fileURLs.length > 0 && (
          <div className="mt-3">
            <h6>Uploaded Files:</h6>
            <ul className="list-unstyled">
              {fileURLs.map((fileURL, index) => {
                const fullFileName = fileURL.split("/").pop();

                // Split by "_" and get the last part
                const fileName = fullFileName.split("_").pop();

                return (
                  <li
                    key={index}
                    className="mb-2 d-flex align-items-center w-100"
                  >
                    <a
                      href={fileURL}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn btn-outline-primary d-flex align-items-center flex-grow-1 text-truncate"
                      style={{
                        textDecoration: "none",
                        padding: "8px",
                        borderRadius: "8px",
                        minWidth: "0", // Allow proper shrinking of buttons
                        marginRight: "0", // Remove right margin
                      }}
                    >
                      <i
                        className="bi bi-file-earmark-fill me-2"
                        style={{ color: "#0e3f70" }}
                      ></i>
                      <span className="text-truncate">
                        {fileName || `File ${index + 1}`}
                      </span>
                    </a>
                    {/* Delete button */}

                    <i
                      className="bi bi-trash text-danger ms-3"
                      onClick={() => handleDeleteFile(index)}
                    ></i>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}

export default InputFileMultiple;
