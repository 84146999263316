import { dialog } from "nq-component";
import BaseListPresenter from "../../base/BaseListPresenter";
import { findObjectUseCase, upsertUseCase } from "../../usecases/object";

class EndorsementPresenter extends BaseListPresenter {
  init() {
    const user = this.view.getCurrentUser();
    console.log("usersssss", user);
    this.limit = 20;
    this.where = {
      clientName: user.name,
    };
    this.search = {};
    this.filter = {};
    this.include = ["all"];
    this.filterStatus = {};
    this.keys = undefined; // if keys are specified, only those keys will be returned
    this.sort = { createdAt: -1 };
    this.progress = true;
    this.filterDate = {};
    this.filterStatus = "";
    this.reset();
  }

  async getObjects() {
    this.reset();
    await this.countObjects();
    await this.findObjects();
    await this.findReport();
  }

  createQuery() {
    const skip = (this.current - 1) * this.limit;
    const query = {
      limit: this.limit,
      skip: skip,
      where: {
        ...this.where,
        ...this.search,
        ...this.filter,
        ...this.filterDate,
        ...this.filterStatus,
      },
      include: this.include,
    };
    if (this.sort) {
      query.sort = this.sort;
    }
    // const keys = this.keys || this.view.getKeys() || [];
    // if (keys.length > 0) {
    //   query.keys = keys;
    // }
    return query;
  }

  async findObjects() {
    const collection = this.view.getCollectionName();
    const query = this.createQuery();

    try {
      this.showProgress();
      this.findObjectUseCase.abort();
      const objects = await this.findObjectUseCase.execute(collection, query);
      this.objects = this.objects.concat(objects);
      this.view.setTotal(this.objects.length);
      this.view.setObjects(this.objects);
      this.hideProgress();
    } catch (error) {
      this.hideProgress();
      this.view.showError(error);
    }
    this.progress = false;
  }

  onClickAdd() {
    this.view.navigateTo("/endorsement-form");
  }

  onClickItem(index) {
    const object = this.objects[index];
    this.view.navigateTo("/endorsement-form/" + object.id);
  }

  onClickView(index, field) {
    const object = this.objects[index];
    this.view.navigateTo("/endorsement-form-view/" + object.id);
  }

  onChangeDate(where) {
    this.filterDate = where;
    this.getObjects();
  }

  filterSubmit(where) {
    const statusName = where.statusFilter?.id;
    this.reset();
    this.filterStatus = statusName;
    this.getObjects();
  }

  onClickDownload(index) {
    // this.view.exportPDF();
    this.findReport(index).then(() => {
      this.view.exportPDF();
    });
  }

  async findReport(index) {
    const query = {
      where: {
        id: this.objects[index]?.qaFormId,
      },
      include: ["answers", "answer.answer", "answers.question"],
    };

    const query1 = {
      where: {
        id: this.objects[index]?.qaFormId,
      },
      include: [
        "answers",
        "answers.choices",
        "answers.question",
        "answers.question.choices",
        "answers.question.question",
      ],
    };

    let report;
    let questions;
    let labelQuestion;
    const rType = await this.objects[index || 0]?.rType;
    // console.log("TTYYY: ", rType);
    // console.log("QUESTTTT: ", questions);
    if (rType === "BUSINESS VERIFICATION (BVR)") {
      report = await findObjectUseCase().execute("bvr_forms_answer_qa", query1);
      const filterYesNo = report[0]?.answers?.filter(
        (item) => item.answers === "Yes" || item.answers === "No"
      );
      const tempResults = [];
      if (filterYesNo.length > 0) {
        for (const filter of filterYesNo) {
          const query = {
            where: {
              id: filter.question.id,
            },
          };
          const result = await findObjectUseCase().execute(
            "bvr_forms_categories_mis",
            query
          );
          const label = await findObjectUseCase().execute(
            "bvr_forms_categories_mis",
            { where: { questionType: "LABEL" } }
          );
          labelQuestion = label;
          const resultObj = result.find((item) => item);
          if (resultObj) {
            tempResults.push(resultObj);
          }
          questions = tempResults;
          // console.log("QUESATT: ", questions);
        }
      }
    } else if (rType === "EMPLOYMENT VERIFICATION (EVR)") {
      report = await findObjectUseCase().execute("evr_forms_answer_qa", query);
      console.log("REP: ", report);
    } else if (rType === "RESIDENCE VERIFICATION (RVR)") {
      report = await findObjectUseCase().execute("rvr_forms_answer_qa", query);
    }
    this.view.setState({ report, index, questions, labelQuestion });
  }

  onClickEdit(index) {
    const object = this.objects[index];
    this.view.navigateTo(`/endorsement-form/${object.id}`);
  }

  async onClickCancel(index) {
    dialog.close();
    const object = this.objects[index];
    console.log("cancel", index);
    object.status = "Cancelled";
    await upsertUseCase().execute("endorsement", object);
    this.objects[index] = object;
    this.view.setObjects(this.objects);
    this.reset();
    this.getObjects();
  }

  onChangeStatus(value) {
    this.reset();
    this.filterStatus = { status: value?.value };
    this.getObjects();
  }
}

export default EndorsementPresenter;
