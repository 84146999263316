import React, { useState, useRef, useEffect } from "react";
import classNames from "../../classNames";
import InputImage from "../InputImage";
import { saveImageUseCase } from "../../usecases/file";

const InputAdd = ({
  object,
  onImagesChange,
  user,
  required = false,
  noButton,
}) => {
  const [items, setItems] = useState([]);
  const wrapperRef = useRef();

  // This useEffect runs when the component mounts or when the `object` changes
  useEffect(() => {
    if (object && object.uploadRelation) {
      // Check if the current `items` state differs from the `uploadRelation` in `object`
      const isEqual =
        JSON.stringify(object.uploadRelation) === JSON.stringify(items);
      if (!isEqual) {
        setItems(object.uploadRelation);
      }
    }
  }, [object]); // Only run when `object` changes

  // This useEffect runs when `items` changes to inform the parent component of the change
  useEffect(() => {
    onImagesChange(items);
  }, [items, onImagesChange]);

  const handleImageChange = (url, index) => {
    console.log("url", url, "index", index);
    const newItems = [...items];
    newItems[index] = { ...newItems[index], image: url };
    setItems(newItems);
  };

  const handleLabelChange = (e, index) => {
    const newItems = [...items];
    newItems[index] = { ...newItems[index], label: e.target.value };
    setItems(newItems);
  };

  const addImage = () => {
    setItems([...items, { image: "", label: "" }]);
  };

  const deleteImage = (index) => {
    const newItems = items.filter((_, i) => i !== index);
    setItems(newItems);
  };

  return (
    <div ref={wrapperRef}>
      <div className="row">
        {items.map((item, index) => (
          <div
            key={`item-${index}`}
            className="mb-3 col-12 d-flex align-items-center"
          >
            <div className="col-11">
              <label className="mb-2" htmlFor={`item-${index}`}>
                Picture
              </label>
              <InputImage
                value={item.image}
                onChange={(url) => handleImageChange(url, index)}
                className="w-100"
                saveImage={saveImageUseCase()}
                user={user}
                required={required}
                noButton={noButton}
              />

              <input
                type="text"
                className={classNames("form-control mt-2")}
                value={item.label || ""}
                placeholder="Label"
                onChange={(e) => handleLabelChange(e, index)}
                disabled={
                  user?.roles[0]?.id === "AGENTS" || user?.roles[0]?.id === "QA"
                }
              />
            </div>

            {user?.roles?.[0]?.id === "MIS" && (
              <div className="col-1 d-flex justify-content-end">
                <span
                  className="cursor-pointer"
                  role="button" // Add accessibility
                  onClick={() => deleteImage(index)}
                >
                  <i className="bi bi-trash" aria-hidden="true"></i>{" "}
                  {/* Add accessibility */}
                </span>
              </div>
            )}
          </div>
        ))}
        {user?.roles[0]?.id === "MIS" && (
          <div className="mb-3 col-12">
            <button
              type="button"
              onClick={addImage}
              className="btn btn-primary"
            >
              Add More Upload
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default InputAdd;
