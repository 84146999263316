import React from "react";
import withRouter from "../../withRouter";
import BaseListPage from "../../base/BaseListPage";
import EndorsementPresenter from "./EndorsementPresenter";
import { countObjectUseCase, findObjectUseCase } from "../../usecases/object";
import NavBar from "../../components/navbar";
import { Button, dialog, InfiniteScroll, Progress } from "nq-component";
import InputFactory from "../../components/InputFactory";
import Search from "../../components/Search";
import TableAudit from "../../components/TableAudit";
import DateRange from "../../components/DateRange";
import printComponent from "../../printComponent";
import Report1 from "../../components/Report2/Report1";
import Report2 from "../../components/Report2/Report2";

import Report3 from "../../components/Report2/Report3";
// import Report1 from "../../components/Reports/Report1";
// import Report2 from "../../components/Reports/Report2";
import SelectSearch from "../../components/SelectSearch";
import Select from "react-select";
import { PrintRVRForm } from "./print-rvr/PrintRVRForm";
// import Report3 from "../../components/Report2/Report3";
import html2pdf from "html2pdf.js";
import { saveFileUseCase } from "../../usecases/file";

async function getBase64ImageFromURL(url) {
  const response = await fetch(url, { mode: "cors" }); // Ensure CORS is enabled
  const blob = await response.blob();
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      resolve(reader.result); // This is the base64 encoded image
    };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
}

class EndorsementPage extends BaseListPage {
  constructor(props) {
    super(props);
    this.state = {
      objects: [],
      selected: [],
      loading: true,
      total: 0,
      count: 0,
      report: [],
      index: "",
      questions: [],
      labelQuestion: [],
    };
    this.presenter = new EndorsementPresenter(
      this,
      findObjectUseCase(),
      countObjectUseCase()
    );
    this.contractPDF = React.createRef();
  }

  getCollectionName() {
    return "endorsement";
  }

  onClickAdd() {
    this.presenter.onClickAdd();
  }

  onClickItem(index, field) {
    this.presenter.onClickItem(index, field);
  }

  onClickView(index, field) {
    this.presenter.onClickView(index, field);
  }

  load() {
    return dialog.fire({
      html: (
        <div className="text-center mt-1">
          <Progress />
          <h6>Processing... Please wait.</h6>
        </div>
      ),
      footer: false,
    });
  }

  async onClickDownload(index) {
    // this.presenter.findReport(index);
    // this.presenter.onClickDownload(index);
    const setPrint = this.state?.objects[index];
    console.log("setPrint", setPrint);
    // dialog.close();
    this.load();
    await this.presenter.findReport(index);
    console.log("THIS IS REPORT", this.state.report);
    this.setState({ sendMailIndex: index }, async () => {
      const element = this.contractPDF.current;

      if (element) {
        // Get all the image elements
        const images = element.querySelectorAll("img");

        // Replace each image src with its base64 equivalent
        const imagePromises = Array.from(images).map(async (img) => {
          console.log("GOGOGOG", img.src);
          try {
            const base64 = await getBase64ImageFromURL(img.src);
            img.src = base64; // Replace the src with base64 encoded image
          } catch (error) {
            console.error("Error loading image:", error);
          }
        });

        try {
          await Promise.all(imagePromises);
          console.log("All images replaced with base64!");

          // Set options for html2pdf
          const opt = {
            margin: 0.5,
            filename: "report.pdf",
            image: { type: "jpeg", quality: 0.98 },
            html2canvas: {
              scale: 3,
              scrollX: 0,
              scrollY: 0,
              windowWidth: element.scrollWidth || document.body.scrollWidth,
              windowHeight: element.scrollHeight || document.body.scrollHeight,
              useCORS: true,
            },
            jsPDF: {
              unit: "pt",
              format: "a4",
              orientation:
                setPrint?.rType === "EMPLOYMENT VERIFICATION (EVR)"
                  ? "landscape"
                  : "portrait",
            },
          };

          html2pdf()
            .from(element)
            .set(opt)
            .output("blob")
            .then((pdfBlob) => {
              pdfBlob.name = "report.pdf";

              saveFileUseCase()
                .execute(pdfBlob)
                .then((result) => {
                  const linkUrl = `${result.url}?applicationId=77c55e9e-2e95-41da-92f1-ccc177ccc59c`;
                  console.log("result", linkUrl);
                  this.setState({ linkUrl }, () => {
                    // this.presenter.sendEmail(index, this.state.linkUrl);
                  });
                })
                .catch((error) => {
                  console.log("error", error);
                });
              const downloadLink = document.createElement("a");
              downloadLink.href = URL.createObjectURL(pdfBlob);
              downloadLink.download = "report.pdf";
              document.body.appendChild(downloadLink);
              downloadLink.click();
              document.body.removeChild(downloadLink);
              dialog.close();
            });
        } catch (error) {
          console.error("Error processing images:", error);
        }
      } else {
        alert("element ref is not available yet");
      }
    });
  }

  exportPDF() {
    printComponent(this.contractPDF.current, "Tasks");
  }

  onClickEdit(index) {
    this.presenter.onClickEdit(index);
  }

  onClickCancel(index) {
    dialog.fire({
      html: (
        <div className="text-center p-3">
          <i
            className="bi bi-question-circle-fill"
            style={{ fontSize: "70px", color: "#fdcc5d" }}
          ></i>
          <h4>Are you sure you want to cancel this endorsement?</h4>
          <button
            className="btn"
            onClick={() => this.presenter.onClickCancel(index)}
            style={{ backgroundColor: "#04376a", color: "white" }}
          >
            YES
          </button>
          <button
            className="btn btn-primary ms-2"
            onClick={() => dialog.close()}
          >
            CANCEL
          </button>
        </div>
      ),
      footer: false,
    });
    // this.presenter.onClickCancel(index);
  }

  onCollapse(index, object) {
    return (
      <>
        <div className="d-flex">
          {object.status === "Dispatched" ||
          object.status === "On going" ||
          object.status === "For Dispatch" ||
          object.status === "For QA" ||
          object.status === "Cancelled" ||
          object.status === "Done" ? (
            <div className="me-1">
              {/* <div>
                <ul className="list-unstyled ms-1">
                  <li>
                    <span className="ms-2 fw-bold" style={{ color: "#004E9B" }}>
                      Loan Title:
                    </span>
                    <span className="fs-sm text-nowrap">
                      {" "}
                      {object.loanTitle}
                    </span>
                  </li>
                  <hr />
                  <h6 style={{ color: "#fdcb5b", marginBottom: "15px" }}>
                    {object?.rType === "BUSINESS VERIFICATION (BVR)"
                      ? "Business Details"
                      : "Resident Details"}
                  </h6>
                  <li>
                    <span className="ms-2 fw-bold text-dark">
                      {object?.rType === "BUSINESS VERIFICATION (BVR)"
                        ? "Business Name: "
                        : "Resident Name:"}
                    </span>
                    <span className="fs-sm text-nowrap">
                      {" "}
                      {object.residentName}
                    </span>
                  </li>

                  <li>
                    <span className="ms-2 fw-bold text-dark">
                      {object?.rType === "BUSINESS VERIFICATION (BVR)"
                        ? "Business Address: "
                        : "Residential Address:"}
                    </span>
                    <span className="fs-sm text-nowrap">
                      {object.residentialAddress}, {object.barangay},{" "}
                      {object.city}, {object.province} {object.region}
                    </span>
                  </li>
                  <li>
                    <span className="ms-2 fw-bold text-dark">
                      Contact Number:{" "}
                    </span>
                    <span className="fs-sm text-nowrap">
                      {object.residentContact}
                    </span>
                  </li>
                  <hr />
                  <h6 style={{ color: "#fdcb5b", marginBottom: "15px" }}>
                    {object?.rType === "BUSINESS VERIFICATION (BVR)"
                      ? "Subject Details"
                      : "Employment Details"}
                  </h6>
                  <li>
                    <span className="ms-2 fw-bold text-dark">
                      {object?.rType === "BUSINESS VERIFICATION (BVR)"
                        ? "Subject Name: "
                        : "Employer's Name:"}
                    </span>
                    <span className="fs-sm text-nowrap">
                      {" "}
                      {object.employerName}
                    </span>
                  </li>

                  <li>
                    <span className="ms-2 fw-bold text-dark">
                      {object?.rType === "BUSINESS VERIFICATION (BVR)"
                        ? "Subject Address: "
                        : "Employer's Address:"}
                    </span>
                    <span className="fs-sm text-nowrap">
                      {object.employerAddress}
                    </span>
                  </li>
                  <li>
                    <span className="ms-2 fw-bold text-dark">
                      Contact Number:{" "}
                    </span>
                    <span className="fs-sm text-nowrap">
                      {object.employerContact}
                    </span>
                  </li>
                </ul>
              </div> */}

              <div className="d-flex">
                {object.picture && (
                  <div
                    className="mb-3"
                    style={{
                      width: "100px",
                      height: "100px",
                    }}
                  >
                    <img
                      className="w-100 h-100"
                      style={{ objectFit: "cover" }}
                      src={object.picture}
                    />
                  </div>
                )}
                <ul className="list-unstyled ms-1">
                  <li>
                    <span className="ms-2 fw-bold" style={{ color: "#003869" }}>
                      Report Type:
                    </span>
                    <span className="fs-sm text-nowrap"> {object.rType}</span>
                  </li>
                  <hr />
                  <li>
                    <span className="ms-2 fw-bold">
                      {object.rType === "BUSINESS VERIFICATION (BVR)"
                        ? "Business Name: "
                        : "Subject Name: "}
                    </span>
                    <span className="fs-sm text-nowrap">
                      {" "}
                      {object.residentName}
                    </span>
                  </li>
                  {object.rType === "EMPLOYMENT VERIFICATION (EVR)" ? (
                    <li>
                      <span className="ms-2 fw-bold">
                        {" "}
                        {object.rType === "BUSINESS VERIFICATION (BVR)"
                          ? "Subject Name: "
                          : "Employer's Name: "}
                      </span>
                      <span className="fs-sm text-nowrap">
                        {" "}
                        {object.employerName}
                      </span>
                    </li>
                  ) : null}
                  <li>
                    <span className="ms-2 fw-bold">
                      {" "}
                      {object.rType === "BUSINESS VERIFICATION (BVR)"
                        ? "Business Address: "
                        : object.rType === "EMPLOYMENT VERIFICATION (EVR)"
                        ? "Employer Address: "
                        : "Residential Address: "}
                    </span>
                    <span className="fs-sm text-nowrap">
                      {object.residentialAddress}, {object.barangay},{" "}
                      {object.city}, {object.province} {object.region}
                    </span>
                  </li>
                  <li>
                    <span className="ms-2 fw-bold">
                      {" "}
                      {object.rType === "BUSINESS VERIFICATION (BVR)"
                        ? "Business Contact Number: "
                        : "Contact Number: "}
                    </span>
                    <span className="fs-sm text-nowrap">
                      {object.residentContact}
                    </span>
                  </li>

                  <hr />
                  {object.rType !== "EMPLOYMENT VERIFICATION (EVR)" &&
                  object.rType !== "RESIDENCE VERIFICATION (RVR)" ? (
                    <>
                      <li>
                        <span className="ms-2 fw-bold">
                          {" "}
                          {object.rType === "BUSINESS VERIFICATION (BVR)"
                            ? "Subject Name: "
                            : "Employer's Name: "}
                        </span>
                        <span className="fs-sm text-nowrap">
                          {" "}
                          {object.employerName}
                        </span>
                      </li>

                      <li>
                        <span className="ms-2 fw-bold">
                          {" "}
                          {object.rType === "BUSINESS VERIFICATION (BVR)"
                            ? "Business Address: "
                            : "Employer's Address: "}{" "}
                        </span>
                        <span className="fs-sm text-nowrap">
                          {object.employerAddress}
                        </span>
                      </li>
                      <li>
                        <span className="ms-2 fw-bold">Contact Number: </span>
                        <span className="fs-sm text-nowrap">
                          {object.employerContact}
                        </span>
                      </li>
                      <hr />
                    </>
                  ) : null}

                  <li>
                    <span className="ms-2 fw-bold">Note:</span>
                    <span
                      className="fs-sm text-nowrap font-italic"
                      style={{ fontStyle: "italic" }}
                    >
                      {" "}
                      {object.note}
                    </span>
                  </li>
                  <li>
                    <span className="ms-2 fw-bold">Attachments:</span>
                    <span
                      className="fs-sm text-nowrap font-italic"
                      style={{ fontStyle: "italic" }}
                    >
                      {object?.file?.map((f, index) => (
                        <a
                          key={index}
                          href={f}
                          target="_blank" // Opens the link in a new tab
                          rel="noopener noreferrer" // Ensures security when opening links
                          className="card p-2 text-decoration-none"
                        >
                          {`Attachment ${index + 1}`}
                        </a>
                      ))}
                    </span>
                  </li>

                  {/* <hr />
            <li>
              <span className="ms-2 fw-light">Location:</span>
              <span className="fs-sm text-nowrap"> {object.location}</span>
            </li> */}
                </ul>
              </div>

              <div className="me-1">
                <div className="d-flex">
                  {object.status !== "For Dispatch" &&
                    object.status !== "Cancelled" && (
                      <button
                        className="btn"
                        onClick={() => this.onClickView(index)}
                        style={{ backgroundColor: "#fdcb5b" }}
                      >
                        <i className="bi bi-pencil-square me-1"></i>VIEW
                        TIMELINE
                      </button>
                    )}
                  {object.status === "Done" && (
                    <div className="me-1">
                      <button
                        className="btn ms-2"
                        onClick={() => this.onClickDownload(index)}
                        style={{ backgroundColor: "#fdcb5b" }}
                      >
                        <i className="bi bi-pencil-square me-1"></i>DOWNLOAD PDF
                      </button>
                    </div>
                  )}
                </div>
                {object.status === "For Dispatch" && (
                  <>
                    <button
                      className="btn ms-2"
                      onClick={() => this.onClickEdit(index)}
                      style={{ backgroundColor: "#fdcb5b" }}
                    >
                      <i className="bi bi-pencil-square me-1"></i>EDIT
                    </button>
                    <button
                      className="btn btn-danger ms-2"
                      onClick={() => this.onClickCancel(index)}
                    >
                      <i className="bi bi-pencil-square me-1"></i>CANCEL
                    </button>
                  </>
                )}
              </div>
            </div>
          ) : (
            object.status === "For Dispatch" && (
              <div className="me-1">
                <button
                  className="btn"
                  onClick={() => this.onClickEdit(index)}
                  style={{ backgroundColor: "#fdcb5b" }}
                >
                  <i className="bi bi-pencil-square me-1"></i>EDIT
                </button>
                <button
                  className="btn btn-danger ms-2"
                  onClick={() => this.onClickCancel(index)}
                >
                  <i className="bi bi-pencil-square me-1"></i>CANCEL
                </button>
              </div>
            )
          )}
        </div>
      </>
    );
  }

  onChangeDate(where) {
    this.presenter.onChangeDate(where);
  }

  // renderReport() {
  //   const { objects, report } = this.state;
  //   console.log("resport", report);
  //   console.log("resport object", objects);
  //   const user = this.getCurrentUser();

  //   // return objects.map((object, index) => {
  //   if (report[0]?.type === "BUSINESS VERIFICATION (BVR)") {
  //     return (
  //       <Report1
  //         // key={index}
  //         objects={objects}
  //         report={report || 0}
  //         user={user}
  //       />
  //     );
  //   } else if (report[0]?.type === "EMPLOYMENT VERIFICATION (EVR)") {
  //     return (
  //       <Report2
  //         // key={index}
  //         objects={objects}
  //         report={report || 0}
  //         user={user}
  //       />
  //     );
  //   } else if (report[0]?.type === "RESIDENCE VERIFICATION (RVR)") {
  //     return (
  //       <Report3
  //         // key={index}
  //         objects={objects}
  //         report={report || 0}
  //         user={user}
  //       />
  //     );
  //   }
  //   return null;
  //   // });
  // }
  renderReport() {
    const { objects, report, index, questions, labelQuestion } = this.state;
    // console.log("report", report);
    // console.log("report object", questions);
    const user = this.getCurrentUser();

    if (!report || report.length === 0) {
      // Handle the case where report is undefined or an empty array
      return <div>No report available</div>;
      // return <Report1 objects={objects} report={report} user={user} />;
    }

    const reportType = objects[index]?.rType;

    switch (reportType) {
      case "BUSINESS VERIFICATION (BVR)":
        return (
          <Report1
            objects={objects[index]}
            report={report}
            user={user}
            questions={questions}
            labelQuestion={labelQuestion}
          />
        );
      case "EMPLOYMENT VERIFICATION (EVR)":
        return <Report2 objects={objects[index]} report={report} user={user} />;
      case "RESIDENCE VERIFICATION (RVR)":
        return <Report3 objects={objects[index]} report={report} user={user} />;
      default:
        return <div>Unknown report type</div>;
    }
  }

  onChangeStatus(value) {
    this.setState({ status: value });
    console.log("bb", value);
    // this.onChangeFilter("String", "Done", "status");
    this.presenter.onChangeStatus(value);
  }

  render() {
    const schema = this.getSchema(this.getCollectionName());
    const user = this.getCurrentUser();
    console.log("user", user);
    const { objects, selected, count, progress } = this.state;
    console.log("Endorserments : ", objects);

    const originalOptions = [
      "For Dispatch",
      "Dispatched",
      "Pending",
      "On going",
      "Done",
      "Cancelled",
    ];

    const adminMapping = {
      Dispatched: "For CI",
      "On going": "For QA",
      Done: "Done",
    };

    const endorserMapping = {
      Dispatched: "Dispatched",
      "On going": "On going",
      Done: "Done",
    };

    const getOptions = (role) => {
      return originalOptions.map((item) => {
        let label;
        if (role === "MIS") {
          label = adminMapping[item] || item;
        } else if (role === "ENDORSER") {
          label = endorserMapping[item] || item;
        } else {
          label = item;
        }
        return { label, value: item };
      });
    };

    const options = getOptions(user.roles[0].id);

    if (!schema) return <Progress />;
    return (
      <>
        <NavBar />
        <div className="d-none">
          <div ref={this.contractPDF}>{this.renderReport()}</div>
        </div>
        <div className="overflow-auto">
          <InfiniteScroll
            className="h-100"
            loadMore={this.loadMore.bind(this)}
            hasMore={!progress && count > objects.length}
          >
            <div className="p-3 p-lg-4">
              <div className="d-flex justify-content-between align-items-center">
                <h1 className="fw-bold text-capitalize">
                  {schema.label || this.getCollectionName()}
                </h1>
                {selected.length > 0 ? (
                  <div>
                    <span className="ms-2">Selected: </span>
                    <span className="fs-sm text-nowrap">{selected.length}</span>
                    <span className="ms-1">of </span>
                    <span className="fs-sm text-nowrap">{count}</span>
                  </div>
                ) : (
                  <div>
                    <span className="ms-2">Total: </span>
                    <span className="fs-sm text-nowrap">{objects.length}</span>
                    <span className="ms-1">of </span>
                    <span className="fs-sm text-nowrap">{count}</span>
                  </div>
                )}
              </div>

              <div className="row mt-3">
                <div className="d-flex gap-3 col-lg-12 mb-2">
                  <DateRange
                    onChange={this.onChangeDate.bind(this)}
                    field="createdAt"
                  />

                  <Select
                    className="basic-single col-3"
                    classNamePrefix="select"
                    isClearable={true}
                    isSearchable={true}
                    name="brand"
                    options={options}
                    onChange={(value) => this.onChangeStatus(value)}
                    placeholder="Select Status"
                  />
                </div>
                <div className="col-sm-3">
                  {Object.keys(schema.filters || {}).map((field) => {
                    let { type, ...options } = schema.filters[field];
                    return (
                      <InputFactory
                        key={field}
                        className="ms-1"
                        type={type}
                        field={field}
                        where={{}}
                        onChange={this.onChangeFilter.bind(this, type)}
                        {...options}
                      />
                    );
                  })}
                </div>
              </div>
              <Search
                schemas={this.getSchemas()}
                className="mt-3"
                onSubmit={this.searchSubmit.bind(this)}
                fields={schema.fields}
              />

              <TableAudit
                fields={schema.fields}
                objects={objects}
                // selectable
                collapsable
                excludeFields={Object.keys(schema.fields).reduce(
                  (acc, key) => {
                    const options = schema.fields[key];
                    if (options.read === false) {
                      acc.push(key);
                    }
                    switch (options._type || options.type) {
                      case "Relation":
                      case "Array":
                      case "Object":
                      case "File":
                        acc.push(key);
                        break;
                      default:
                    }
                    return acc;
                  },
                  [
                    "acl",
                    "id",

                    "password",
                    "username",
                    "firstName",
                    "middleName",
                    "lastName",
                    "picture",
                    "signature",
                    "email",
                    "residentName",
                    "residentialAddress",
                    "residentContact",
                    "employerName",
                    "employerAddress",
                    "employerContact",
                    // "reportType",
                    "rate",
                    "dispatchType",
                    // "rType",
                    "clientName",
                    "borrowerName",
                    "location",
                    "statusQA",
                    "note",
                    "client",
                  ]
                )}
                selected={selected}
                onSelect={this.onSelect.bind(this)}
                onSelectAll={this.onSelectAll.bind(this)}
                progress={progress}
                onClickItem={this.onClickItem.bind(this)}
                onCollapse={this.onCollapse.bind(this)}
                className="mt-3"
              />
            </div>
          </InfiniteScroll>
        </div>
        <div className="position-fixed bottom-0 end-0 m-4">
          <Button
            className="btn shadow-sm"
            onClick={this.onClickAdd.bind(this)}
            style={{
              width: "50px",
              height: "50px",
              borderRadius: "25px",
              backgroundColor: "#fdcb5b",
            }}
          >
            <i
              className="bi bi-plus-lg"
              style={{ fontSize: "20px", color: "black" }}
            />
          </Button>
        </div>
      </>
    );
  }
}

export default withRouter(EndorsementPage);
