import React, { Component } from "react";
import BaseFormPresenter from "../../../base/BaseFormPresenter";
import { dialog } from "nq-component";

class DispatchDialogPresenter extends BaseFormPresenter {
  async save(object) {
    // Format the current date and time in the Philippines time zone
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
      timeZone: "Asia/Manila",
    };
    const formatter = new Intl.DateTimeFormat("en-GB", options);
    const parts = formatter.formatToParts(new Date());
    const timeString = `${parts.find((p) => p.type === "year").value}-${
      parts.find((p) => p.type === "month").value
    }-${parts.find((p) => p.type === "day").value} ${
      parts.find((p) => p.type === "hour").value
    }:${parts.find((p) => p.type === "minute").value}:${
      parts.find((p) => p.type === "second").value
    }`;

    object.dispatchType = this.change.dispatchType;
    object.location = this.change.location;
    object.rate = this.change.rate;
    object.notesCI = this.change.notesCI;
    object.file2 = this.change.file2;

    if (this.change.dispatchType === "Work in Progress") {
      object.status = "Dispatched";
    } else if (this.change.dispatchType === "Pending") {
      object.status = "Pending";
    }
    object.agent = this.view.state.selectedUser.label;
    object.startTime = this.view.state.selectedTurnTime?.label || "";
    object.timeCompleted = this.view.state.selectedTime;
    object.dispatch = true;
    object.timeline = object.timeline || [];
    object.timeline.push({
      time: timeString,
      status: object.status,
    });
    console.log("this", object);
    const collection = this.view.getCollectionName();
    if (this.object.id) {
      this.change.id = this.object.id;
    } else {
      // this.change.acl = this.view.getAcl();
    }
    try {
      await this.upsertUseCase.execute("endorsement", object);
      this.change = {};
    } catch (error) {
      throw error; // rethrow the error to be caught by the caller
    }
  }

  async submit(object) {
    dialog.close();
    // alert("hi");
    // if (Object.values(this.change).length === 0) {
    //   this.view.showSuccessSnackbar("Successfully saved!");
    //   return;
    // }
    try {
      this.view.submitting();
      await this.save(object);
      this.view.submissionSuccess();
      dialog.close();
      this.view.navigateTo("/dispatched-page");
      this.view.showSuccessSnackbar("Successfully saved!");
    } catch (error) {
      this.view.submissionError(error);
      this.view.showError(error);
    }
  }
}

export default DispatchDialogPresenter;
