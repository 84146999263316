import BaseFormPresenter from "../../base/BaseFormPresenter";
import {
  findObjectUseCase,
  saveObjectUseCase,
  updateObjectUseCase,
} from "../../usecases/object";

const remarksTemplate = `RESULT:
*All information was gathered through ______________
*Informant relayed that the subject has been employed at _____________ as _______________ for ____________ while spouse is employed at __________________ as ________ for ________
*Premises checked is ____________
*Grey Area - ______________________


Is this the first time for customer to apply motorcycle loan?
() Yes          () No
Motorcycle Dealer Name/Branch: 
Date/Period of previous loan: 
Status of previous loan: 

MOTORCYCLE PURPOSE & OTHER DETAILS?
What is the purpose of Motorcycle: Personal Use
Who is the user of motorcycle: 
Who will pay for the down payment: 
Who will pay the monthly amortization: 

CHARACTER
Face-to-face interview 
() Good character 
() Bad character 
() No face-to-face
() Availability of the Client:`;

const remarksTemplate1 = `INFORMANTS
Informant 1: 
Relationship:
Contact No.: 
Personally Known:
Any known credit history of the applicant:  
Have you ever witnessed collectors visiting the said residence: 
Other Remarks/Comment: 
 
Informant 2: 
Relationship:
Contact No.: 
Personally Known: 
Any known credit history of the applicant:  
Have you ever witnessed collectors visiting the said residence: 
Other Remarks/Comment:  

Barangay Checking/Homeowners Association
() Good (no bad record)     () Bad (with negative records)     () Not known
Informant:
Position:
Contact Number:
Personally Known: 
Registered Voter:

OTHER REMARKS (if any): N/A`;

class CreditForm3Presenter extends BaseFormPresenter {
  onChangeObject(object) {
    this.change = object;
    this.object = object;
  }

  async getObject() {
    const collection = this.view.getCollectionName();
    const id = this.object.id || this.view.getObjectId();
    if (id) {
      const params = {
        include: [
          "categories",
          "categories.questions",
          "categories.questions.question",
        ],
      };
      try {
        this.view.showProgress();
        this.object = await this.getObjectUseCase.execute(collection, id, {
          params,
        });
        this.view.hideProgress();
        const answer = { answers: [] };
        answer.form = { id: this.object.id };
        answer.remarks = remarksTemplate;
        answer.remarks2 = remarksTemplate1;
        console.log("answer", answer);
        this.view.setAnswer(answer);
        console.log("evr form", this.object);
        this.view.setObject(this.object);
      } catch (error) {
        this.view.hideProgress();
        this.view.showError(error);
      }
    }
  }

  async save() {
    const collection = this.view.getCollectionName();
    const objectUpload = this.view.getUpload();
    const objectAnswer = this.view.getAnswer();
    const collectionEndorsement = "endorsement";
    const collectionUsers = "users";
    const currentDate = new Date().toISOString();
    objectAnswer.uploadRelation = objectUpload;

    const url = this.view.getParams();
    const agentUser = this.view.getCurrentUser();

    const parts = url["*"].split("/");
    console.log("parts", parts);
    const secondPart = parts[1];
    const indexPart = parts[2];
    const query = {
      where: {
        id: secondPart,
      },
    };
    const queryUser = {
      sort: {
        createdAt: 1,
      },
      include: ["All", "roles.name", "location.name"],
    };

    const getCurrentIndex = await findObjectUseCase().execute("current_index", {
      id: "23883ba9-0672-43e7-b0f3-42717fdca42c",
    });

    let currentIndex = getCurrentIndex[0]?.currentIndex || 0;
    const users = await findObjectUseCase().execute("users", queryUser);

    // // Filter users with the role QA
    // const qaUsers = users.filter((user) =>
    //   user.roles.some((role) => role.id === "QA")
    // );

    // // Sort MISUsers by createdAt to establish initial order
    // qaUsers.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

    // const sortedQAUsers = qaUsers.sort((a, b) => {
    //   const aEndorsementCount = a.endorsement ? a.endorsement.length : 0;
    //   const bEndorsementCount = b.endorsement ? b.endorsement.length : 0;

    //   const check = aEndorsementCount === bEndorsementCount ? true : false;

    //   if (aEndorsementCount === bEndorsementCount) {
    //     return new Date(a.createdAt) - new Date(b.createdAt);
    //   }
    //   const minus = aEndorsementCount - bEndorsementCount;
    //   console.log("minus", minus);
    //   return minus;
    // });

    // // Select the user with the fewest endorsements, or the earliest created if counts are equal
    // const userForQA = sortedQAUsers[0];
    // console.log("Next user for QA:", userForQA);

    // let qaUsers = users.filter((user) =>
    //   user.roles.some((role) => role.id === "QA")
    // );

    let qaUsers = users.filter(
      (user) =>
        user.roles.some((role) => role.id === "QA") && user.status === "Active"
    );

    // Sort QA users by `createdAt` to maintain order
    qaUsers.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

    // Check if the currentIndex is out of bounds (in case of new users)
    if (currentIndex >= qaUsers.length) {
      currentIndex = 0; // Reset to the first user if necessary
    }

    // Distribute endorsement to the next user in sequence
    const userForQA = qaUsers[currentIndex];

    console.log("Next user for QA:", userForQA);

    const findEndorsement = await findObjectUseCase().execute(
      collectionEndorsement,
      query
    );

    // let endorsementQA = findEndorsement[indexPart];
    let endorsementQA = findEndorsement[0];
    console.log("endorsementQA", endorsementQA);

    const queryEndorsementQA = {
      where: {
        id: endorsementQA.id,
      },
    };

    const findEndorsementQa = await findObjectUseCase().execute(
      "endorsement",
      queryEndorsementQA
    );

    let options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
      timeZone: "Asia/Manila",
    };
    const formatter = new Intl.DateTimeFormat("en-GB", options);
    let partss = formatter.formatToParts(new Date());
    const timeString = `${partss.find((p) => p.type === "year").value}-${
      partss.find((p) => p.type === "month").value
    }-${partss.find((p) => p.type === "day").value} ${
      partss.find((p) => p.type === "hour").value
    }:${partss.find((p) => p.type === "minute").value}:${
      partss.find((p) => p.type === "second").value
    }`;

    const formatDate = (date) => {
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
      const year = String(date.getFullYear()); // Get the last two digits of the year
      return `${year}-${month}-${day}`;
    };

    const date = new Date();
    const formattedDate = formatDate(date);

    endorsementQA.status = "On going";
    endorsementQA.statusQA = "Pending";
    endorsementQA.dateTimeSubmitted = timeString;
    endorsementQA.agentSignature = agentUser.signature;
    if (qaUsers.some((qa) => qa?.id === findEndorsementQa[0].createdBy)) {
      console.log("hey");
    } else {
      endorsementQA.createdBy = userForQA?.id;
    }

    // endorsementQA.timeline?.push({
    //   time: formattedDate + " " + endorsementQA.startTime,
    //   status: "For CI",
    // });
    endorsementQA.timeline = endorsementQA?.timeline || [];
    endorsementQA.timeline?.push({
      time: timeString,
      status: "On going",
    });
    userForQA.endorsement = userForQA.endorsement || [];
    userForQA.endorsement.push({
      id: secondPart,
      createdAt: currentDate,
    });

    // currentIndex = (currentIndex + 1) % qaUsers.length;
    currentIndex = (currentIndex % qaUsers.length) + 1;

    if (currentIndex === 0) {
      await saveObjectUseCase().execute("current_index", {
        id: "23883ba9-0672-43e7-b0f3-42717fdca42c",
        currentIndex: currentIndex,
      });
    } else {
      await updateObjectUseCase().execute("current_index", {
        id: "23883ba9-0672-43e7-b0f3-42717fdca42c",
        currentIndex: currentIndex,
      });
    }

    console.log("send nw", objectAnswer);

    try {
      const response = await saveObjectUseCase().execute(
        "rvr_forms_answer_mis",
        objectAnswer
      );

      endorsementQA.qaFormId = response.id;
      endorsementQA.assignedQA = userForQA?.name;
      await this.upsertUseCase.execute(collectionEndorsement, endorsementQA);
      // await this.upsertUseCase.execute(collectionUsers, userForQA);
      if (qaUsers.some((qa) => qa?.id === findEndorsementQa[0].createdBy)) {
        return;
      } else {
        // await this.upsertUseCase.execute(collectionUsers, userForQA);
        const { password, ...userForQAWithoutPassword } = userForQA; // Exclude password
        console.log("userForQAWithoutPassword", userForQAWithoutPassword);
        await this.upsertUseCase.execute(
          collectionUsers,
          userForQAWithoutPassword
        );
      }
      this.change = {};
    } catch (error) {
      throw error; // rethrow the error to be caught by the caller
    }
  }

  async submit() {
    // if (Object.values(this.change).length === 0) {
    //   this.view.showSuccessSnackbar("Successfully saved!");
    //   return;
    // }
    try {
      this.view.submitting();
      await this.save();
      this.view.submissionSuccess();
      this.view.showSuccessSnackbar("Successfully saved!");
      this.view.navigateBack();
    } catch (error) {
      this.view.submissionError(error);
      this.view.showError(error);
    }
  }
}

export default CreditForm3Presenter;
