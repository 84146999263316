// import React from "react";
// import click from "../../click";
// import classNames from "../../classNames";
// import printComponent from "../../printComponent";

// const defaultProps = {
//   height: "250px",
// };

// function InputImage({
//   className,
//   value,
//   onChange,
//   saveImage,
//   print,
//   height,
//   required,
// }) {
//   const [progress, setProgress] = React.useState(0);
//   const [image, setImage] = React.useState(null);
//   const printRef = React.useRef();

//   React.useEffect(() => {
//     if (value) {
//       setImage(value);
//     }
//   }, [value]);

//   function readImage(file) {
//     const reader = new FileReader();
//     reader.onload = (e) => {
//       setImage(e.target.result);
//     };
//     reader.readAsDataURL(file);
//   }

//   function onFileSelected(file) {
//     readImage(file);
//     const options = {
//       progress: (value) => setProgress(value * 100),
//     };
//     saveImage
//       .execute(file, options)
//       .then((result) => {
//         setImage(result.url);
//         setProgress(0);
//         onChange(result.url);
//       })
//       .catch((error) => {
//         console.log("error", error);
//       });
//   }

//   function onClick() {
//     const input = document.createElement("input");
//     input.type = "file";
//     input.accept = "img";
//     input.onchange = (e) => {
//       const [file] = e.target.files;
//       file && onFileSelected(file);
//     };
//     click(input);
//   }

//   function onClickIcon(e) {
//     e.stopPropagation();
//     printComponent(printRef.current, "image")
//       .then((result) => {
//         console.log(result);
//       })
//       .catch((error) => {
//         alert(error);
//       });
//   }

//   return (
//     <>
//       <div className="d-none">
//         <div ref={printRef}>
//           {value && <img src={value} alt="Image" style={{ margin: "100px" }} />}
//         </div>
//       </div>
//       <div
//         onClick={onClick}
//         className={classNames(
//           "rounded bg-light d-flex position-relative cursor-pointer",
//           className,
//           required && !image ? "border border-danger" : ""
//         )}
//         style={{ height }}
//       >
//         <div className="position-absolute h-100 w-100 d-flex justify-content-center">
//           <img className="img-fluid" src={image || value} />
//         </div>
//         {!(image || value) && (
//           <div className="position-absolute h-100 w-100 d-flex">
//             <i className="bi bi-camera-fill display-3 text-dark m-auto" />
//           </div>
//         )}
//         {progress > 0 && (
//           <div className="position-absolute h-100 w-100 bg-black bg-opacity-50" />
//         )}
//         {progress > 0 && (
//           <div className="position-absolute h-100 w-100 d-flex">
//             <h3 className="m-auto text-white">{progress.toFixed(0)}%</h3>
//           </div>
//         )}
//         {print && (
//           <div className="position-absolute w-100 text-end p-2">
//             <button
//               type="button"
//               onClick={onClickIcon}
//               className="btn btn-secondary"
//             >
//               <i className="bi bi-printer-fill"></i>
//             </button>
//           </div>
//         )}
//       </div>
//     </>
//   );
// }

// InputImage.defaultProps = defaultProps;
// export default InputImage;

import React from "react";
import click from "../../click";
import classNames from "../../classNames";
import printComponent from "../../printComponent";

const defaultProps = {
  height: "250px",
};

function InputImage({
  className,
  value,
  onChange,
  saveImage,
  print,
  height,
  user,
  required,
  noButton,
}) {
  const [progress, setProgress] = React.useState(0);
  const [image, setImage] = React.useState(null);
  const printRef = React.useRef();

  React.useEffect(() => {
    if (value) {
      setImage(value);
    }
  }, [value]);

  function readImage(file) {
    const reader = new FileReader();
    reader.onload = (e) => {
      setImage(e.target.result);
    };
    reader.readAsDataURL(file);
  }

  function onFileSelected(file) {
    readImage(file);
    const options = {
      progress: (value) => setProgress(value * 100),
    };
    saveImage
      .execute(file, options)
      .then((result) => {
        setImage(result.url);
        setProgress(0);
        onChange(result.url);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  function onClick() {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "img";
    input.onchange = (e) => {
      const [file] = e.target.files;
      file && onFileSelected(file);
    };
    click(input);
  }

  function onClickIcon(e) {
    e.stopPropagation();
    printComponent(printRef.current, "image")
      .then((result) => {
        console.log(result);
      })
      .catch((error) => {
        alert(error);
      });
  }

  function handleDownload(e) {
    e.stopPropagation();
    if (image || value) {
      let downloadUrl = image || value;

      // Check if the URL starts with "http:" and replace with "https:"
      if (downloadUrl.startsWith("http:")) {
        downloadUrl = downloadUrl.replace("http:", "https:");
      }
      fetch(downloadUrl)
        .then((response) => response.blob())
        .then((blob) => {
          console.log("blob", blob);
          const url = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.download = "downloaded_image.jpg";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(url);
        })
        .catch((error) => {
          console.error("Error downloading the image:", error);
          alert("Failed to download the image.");
        });
    } else {
      alert("No image to download.");
    }
  }

  const handleDelete = (e) => {
    e.stopPropagation(); // Prevent triggering parent click event
    setImage(null); // Clear local image state
    onChange(" "); // Propagate the empty string to the parent
  };

  return (
    <>
      <div className="d-none">
        <div ref={printRef}>
          {value && <img src={value} alt="Image" style={{ margin: "100px" }} />}
        </div>
      </div>
      <div
        onClick={onClick}
        className={classNames(
          "rounded bg-light d-flex position-relative cursor-pointer",
          className,
          required && !image ? "border border-danger" : ""
        )}
        style={{ height }}
      >
        <div className="position-absolute h-100 w-100 d-flex justify-content-center">
          <img className="img-fluid" src={image || value} />
        </div>
        {/* {!(image || value) && (
          <div className="position-absolute h-100 w-100 d-flex">
            <i className="bi bi-camera-fill display-3 text-dark m-auto" />
          </div>
        )} */}
        {!(image || value) || image === " " || value === " " ? (
          <div className="position-absolute h-100 w-100 d-flex">
            <i className="bi bi-camera-fill display-3 text-dark m-auto" />
          </div>
        ) : null}

        {progress > 0 && (
          <div className="position-absolute h-100 w-100 bg-black bg-opacity-50" />
        )}
        {progress > 0 && (
          <div className="position-absolute h-100 w-100 d-flex">
            <h3 className="m-auto text-white">{progress.toFixed(0)}%</h3>
          </div>
        )}
        {print && (
          <div className="position-absolute w-100 text-end p-2">
            <button
              type="button"
              onClick={onClickIcon}
              className="btn btn-secondary"
            >
              <i className="bi bi-printer-fill"></i>
            </button>
          </div>
        )}

        {(image || value) && (
          <div className="position-absolute w-100 text-end p-2">
            {user?.roles[0]?.id === "QA" && (
              <>
                {" "}
                {noButton && (
                  <>
                    <button
                      type="button"
                      onClick={handleDownload}
                      className="btn btn-success me-2"
                    >
                      <i className="bi bi-download"></i>
                    </button>
                    <button
                      type="button"
                      onClick={handleDelete}
                      className="btn btn-danger"
                    >
                      <i className="bi bi-trash"></i>
                    </button>
                  </>
                )}
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
}

InputImage.defaultProps = defaultProps;
export default InputImage;
