import React from "react";
import withRouter from "../../withRouter";
import BaseListPage from "../../base/BaseListPage";
import { countObjectUseCase, findObjectUseCase } from "../../usecases/object";
import NavBar from "../../components/navbar";
import { InfiniteScroll, Progress } from "nq-component";
import Search from "../../components/Search";
import TableAudit from "../../components/TableAudit";
import CreditInvestigatePresenter from "./CreditInvestigatePresenter";

class CreditInvestigatePage extends BaseListPage {
  constructor(props) {
    super(props);
    this.state = {
      objects: [],
      selected: [],
      loading: true,
      total: 0,
      count: 0,
      hoveredIndex: null,
    };
    this.presenter = new CreditInvestigatePresenter(
      this,
      findObjectUseCase(),
      countObjectUseCase()
    );
  }

  getCollectionName() {
    return "endorsement";
  }

  onClickAdd() {
    this.presenter.onClickAdd();
  }

  onClickItem(index, field) {
    this.presenter.onClickItem(index, field);
  }

  onClickView(index, field) {
    this.presenter.onClickView(index, field);
  }

  onClickItemAudit(index, field) {
    this.presenter.onClickItemAudit(index, field);
  }

  handleMouseEnter = (index) => {
    const object = this.state.objects[index];
    if (object.status === "Pending") {
      this.setState({ hoveredIndex: index });
    }
  };

  handleMouseLeave = () => {
    this.setState({ hoveredIndex: null });
  };

  // isCurrentTimeMatch(startTime) {
  //   const currentTime = new Date();

  //   // Parse the startTime string to a Date object
  //   const [time, period] = startTime.split(" ");
  //   const [hours, minutes] = time.split(":").map(Number);

  //   // Convert to 24-hour format
  //   let startHours = hours;
  //   if (period === "PM" && hours !== 12) {
  //     startHours += 12;
  //   } else if (period === "AM" && hours === 12) {
  //     startHours = 0;
  //   }

  //   const startDate = new Date();
  //   startDate.setHours(startHours, minutes, 0, 0);

  //   // Check if current time is greater than or equal to startTime
  //   return currentTime >= startDate;
  // }

  isCurrentTimeMatchOrGreater(startTime) {
    const currentTime = new Date();

    // Parse the startTime string to a Date object
    const [time, period] = startTime.split(" ");
    const [hours, minutes] = time.split(":").map(Number);

    // Convert to 24-hour format
    let startHours = hours;
    if (period === "PM" && hours !== 12) {
      startHours += 12;
    } else if (period === "AM" && hours === 12) {
      startHours = 0;
    }

    // Create a Date object for the startTime
    const startDate = new Date(currentTime);
    startDate.setHours(startHours, minutes, 0, 0);

    // Check if current time is greater than or equal to startTime
    return currentTime >= startDate;
  }

  // isCurrentTimeMatchOrGreater(startTime) {
  //   const currentTime = new Date();

  //   // Parse the startTime string to a Date object
  //   const [time, period] = startTime.split(" ");
  //   const [hours, minutes] = time.split(":").map(Number);

  //   // Convert to 24-hour format
  //   let startHours = hours;
  //   if (period === "PM" && hours !== 12) {
  //     startHours += 12;
  //   } else if (period === "AM" && hours === 12) {
  //     startHours = 0;
  //   }

  //   // Create a Date object for the startTime
  //   const startDate = new Date();
  //   startDate.setHours(startHours, minutes, 0, 0);

  //   // If current time is past startTime for today, set startTime to tomorrow
  //   if (currentTime >= startDate) {
  //     startDate.setDate(startDate.getDate() + 1);
  //   }

  //   // Check if current time is greater than or equal to startTime
  //   return currentTime >= startDate;
  // }

  onCollapse(index, object) {
    const { hoveredIndex } = this.state;
    const showTooltip = hoveredIndex === index;

    return (
      <>
        <div className="d-flex">
          {object.picture && (
            <div
              className="mb-3"
              style={{
                width: "100px",
                height: "100px",
              }}
            >
              <img
                className="w-100 h-100"
                style={{ objectFit: "cover" }}
                src={object.picture}
              />
            </div>
          )}
          <ul className="list-unstyled ms-1">
            <li>
              <span className="ms-2 fw-bold" style={{ color: "#003869" }}>
                Report Type:
              </span>
              <span className="fs-sm text-nowrap"> {object.rType}</span>
            </li>
            <hr />
            <li>
              <span className="ms-2 fw-bold">
                {object.rType === "BUSINESS VERIFICATION (BVR)"
                  ? "Business Name: "
                  : "Subject Name: "}
              </span>
              <span className="fs-sm text-nowrap"> {object.residentName}</span>
            </li>
            {object.rType === "EMPLOYMENT VERIFICATION (EVR)" ? (
              <li>
                <span className="ms-2 fw-bold">
                  {" "}
                  {object.rType === "BUSINESS VERIFICATION (BVR)"
                    ? "Subject Name: "
                    : "Employer's Name: "}
                </span>
                <span className="fs-sm text-nowrap">
                  {" "}
                  {object.employerName}
                </span>
              </li>
            ) : null}
            <li>
              <span className="ms-2 fw-bold">
                {" "}
                {object.rType === "BUSINESS VERIFICATION (BVR)"
                  ? "Business Address: "
                  : object.rType === "EMPLOYMENT VERIFICATION (EVR)"
                  ? "Employer Address: "
                  : "Residential Address: "}
              </span>
              <span className="fs-sm text-nowrap">
                {object.residentialAddress}, {object.barangay}, {object.city},{" "}
                {object.province} {object.region}
              </span>
            </li>
            <li>
              <span className="ms-2 fw-bold">
                {" "}
                {object.rType === "BUSINESS VERIFICATION (BVR)"
                  ? "Business Contact Number: "
                  : "Contact Number: "}
              </span>
              <span className="fs-sm text-nowrap">
                {object.residentContact}
              </span>
            </li>

            <hr />
            {object.rType !== "EMPLOYMENT VERIFICATION (EVR)" &&
            object.rType !== "RESIDENCE VERIFICATION (RVR)" ? (
              <>
                <li>
                  <span className="ms-2 fw-bold">
                    {" "}
                    {object.rType === "BUSINESS VERIFICATION (BVR)"
                      ? "Subject Name: "
                      : "Employer's Name: "}
                  </span>
                  <span className="fs-sm text-nowrap">
                    {" "}
                    {object.employerName}
                  </span>
                </li>

                <li>
                  <span className="ms-2 fw-bold">
                    {" "}
                    {object.rType === "BUSINESS VERIFICATION (BVR)"
                      ? "Business Address: "
                      : "Employer's Address: "}{" "}
                  </span>
                  <span className="fs-sm text-nowrap">
                    {object.employerAddress}
                  </span>
                </li>
                <li>
                  <span className="ms-2 fw-bold">Contact Number: </span>
                  <span className="fs-sm text-nowrap">
                    {object.employerContact}
                  </span>
                </li>
                <hr />
              </>
            ) : null}

            <li>
              <span className="ms-2 fw-bold">Note:</span>
              <span
                className="fs-sm text-nowrap font-italic"
                style={{ fontStyle: "italic" }}
              >
                {" "}
                {object.note}
              </span>
            </li>
            <li>
              <span className="ms-2 fw-bold">Attachments:</span>
              <span
                className="fs-sm text-nowrap font-italic"
                style={{ fontStyle: "italic" }}
              >
                {object?.file?.map((f, index) => (
                  <a
                    key={index}
                    href={f}
                    target="_blank" // Opens the link in a new tab
                    rel="noopener noreferrer" // Ensures security when opening links
                    className="card p-2 text-decoration-none"
                  >
                    {`Attachment ${index + 1}`}
                  </a>
                ))}
              </span>
            </li>

            {/* <hr />
            <li>
              <span className="ms-2 fw-light">Location:</span>
              <span className="fs-sm text-nowrap"> {object.location}</span>
            </li> */}
          </ul>
        </div>
        <div className="d-flex">
          {object.statusQA === "Pending" ||
          object.statusQA === "Approved" ||
          object.statusQA === "Disapproved" ||
          object.statusQA === "Done" ? null : (
            <div
              className="me-1"
              onMouseEnter={() => this.handleMouseEnter(index)}
              onMouseLeave={this.handleMouseLeave}
              style={{ position: "relative" }}
            >
              {object.status === "Pending" ? (
                <button
                  className="btn"
                  onClick={() => this.onClickItemAudit(index)}
                  style={{ backgroundColor: "#fdcb5b" }}
                  // disabled={object.status === "Pending"}
                  disabled={!this.isCurrentTimeMatchOrGreater(object.startTime)}
                >
                  <i className="bi bi-pencil-square me-1"></i>AUDIT
                </button>
              ) : (
                <button
                  className="btn"
                  onClick={() => this.onClickItemAudit(index)}
                  style={{ backgroundColor: "#fdcb5b" }}
                  disabled={object.status === "Pending"}
                >
                  <i className="bi bi-pencil-square me-1"></i>AUDIT
                </button>
              )}
              {showTooltip && (
                <div className="">
                  Your start time is {object.startTime} tomorrow
                </div>
              )}
            </div>
          )}
        </div>
      </>
    );
  }

  render() {
    const schema = this.getSchema(this.getCollectionName());
    const { objects, selected, count, progress } = this.state;
    console.log("credit", objects);
    if (!schema) return <Progress />;
    const user = this.getCurrentUser();
    return (
      <>
        <NavBar />
        <div className="overflow-auto">
          <InfiniteScroll
            className="h-100"
            loadMore={this.loadMore.bind(this)}
            hasMore={!progress && count > objects.length}
          >
            <div className="p-3 p-lg-4">
              <div className="d-flex justify-content-between align-items-center">
                <h1 className="fw-bold text-capitalize">
                  {schema.label || "Credit Investigate"}
                </h1>
                {selected.length > 0 ? (
                  <div>
                    <span className="ms-2">Selected: </span>
                    <span className="fs-sm text-nowrap">{selected.length}</span>
                    <span className="ms-1">of </span>
                    <span className="fs-sm text-nowrap">{count}</span>
                  </div>
                ) : (
                  <div>
                    <span className="ms-2">Total: </span>
                    <span className="fs-sm text-nowrap">{objects.length}</span>
                    <span className="ms-1">of </span>
                    <span className="fs-sm text-nowrap">{count}</span>
                  </div>
                )}
              </div>

              <Search
                schemas={this.getSchemas()}
                className="mt-3"
                onSubmit={this.searchSubmit.bind(this)}
                fields={schema.fields}
              />
              <TableAudit
                fields={schema.fields}
                objects={objects}
                // selectable
                collapsable
                excludeFields={Object.keys(schema.fields).reduce(
                  (acc, key) => {
                    const options = schema.fields[key];
                    if (options.read === false) {
                      acc.push(key);
                    }
                    switch (options._type || options.type) {
                      case "Relation":
                      case "Array":
                      case "Object":
                      case "File":
                        acc.push(key);
                        break;
                      default:
                    }
                    return acc;
                  },
                  [
                    "acl",
                    "password",
                    "username",
                    "firstName",
                    "middleName",
                    "lastName",
                    "picture",
                    "signature",
                    "email",
                    "residentialAddress",
                    "residentContact",
                    "employerName",
                    "employerAddress",
                    "employerContact",
                    "reportType",
                    "rate",
                    "dispatchType",
                    // "rType",
                    "loanTitle",
                    "clientName",
                    "borrowerName",
                    "status",
                    "statusQA",
                    "id",
                    "note",
                    "residentName",
                  ]
                )}
                selected={selected}
                onSelect={this.onSelect.bind(this)}
                onSelectAll={this.onSelectAll.bind(this)}
                progress={progress}
                onClickItem={this.onClickItem.bind(this)}
                onCollapse={this.onCollapse.bind(this)}
                className="mt-3"
              />
            </div>
          </InfiniteScroll>
        </div>
      </>
    );
  }
}

export default withRouter(CreditInvestigatePage);
